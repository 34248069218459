import locateInSurveyStore from './locateInSurveyStore';

export default function CurveGenerator(t, category, pureValue, ampLevel, limb){
    let curveParameters = {
        "rightUpper": {
            "a": -2.1,
            "b": 0.5
        },
        "rightLower": {
            "a": -1.8,
            "b": 0.1
        },
        "leftUpper": {
            "a": -2.1,
            "b": -0.5
        },
        "leftLower": {
            "a": -1.8,
            "b": -0.1
        }
    };
    let specialCases = {
        "rightUpper": {
            "0": {
                "1": {
                    left: 1.35*t*curveParameters[category].b
                },
                "2": {
                    left: 1.5*t*curveParameters[category].b
                },
                "3": {
                    left: 1.35*t*curveParameters[category].b
                },
                "4": {
                    left: 1.35*t*curveParameters[category].b,
                    rotate: pureValue/20
                },
                "5": {
                    left: 1.35*t*curveParameters[category].b,
                    rotate: pureValue/20
                },
                "6": {
                    left: 1.2*t*curveParameters[category].b,
                    rotate: pureValue/10
                },
                "7": {
                    left: 1.2*t*curveParameters[category].b,
                    rotate: limb.element == 1 ? pureValue/20 : 0
                }
            },
            "45": {
                "1": {
                    left: 0.3*t*curveParameters[category].b + 70
                },
                "2": {
                    left: 0.3*t*curveParameters[category].b + 70
                }
            },
            "60": {
                "7": {
                    left: 1.2*t*curveParameters[category].b,
                    top: limb.element == 1 ? 1.4*t*curveParameters[category].a + 20 : (limb.element == 2 ? 0.9*t*curveParameters[category].a - 5 : 0.6*t*curveParameters[category].a - 21),
                    rotate: limb.element == 1 ? pureValue/20 : 0
                }
            },
            "65": {
                "1": {
                    left: t*curveParameters[category].b - 5*(pureValue - 65),
                    rotate: 2.3*(pureValue - 65),
                    scale: 1
                },
                "3": {
                    top: limb.element == 1 ? 1.15*t*curveParameters[category].a + 44 : (limb.element == 2 ? 0.9*t*curveParameters[category].a - 24 : 0.8*t*curveParameters[category].a - 52),
                    left: 0.3*t*curveParameters[category].b + 67
                },
                "4": {
                    top: limb.element == 1 ? t*curveParameters[category].a : 0.65*t*curveParameters[category].a - 70,
                    left: 1.35*t*curveParameters[category].b,
                    rotate:5
                },
                "5": {
                    top: limb.element == 1 ? 1.1*t*curveParameters[category].a + 15 : (limb.element == 2 ? 0.68*t*curveParameters[category].a - 50 : 0.55*t*curveParameters[category].a - 75),
                    left: limb.element == 1 ? 1.35*t*curveParameters[category].b : 1.1*t*curveParameters[category].b + 10,
                    rotate: pureValue/20
                },
                "6": {
                    left: limb.element == 1 ? 1.2*t*curveParameters[category].b : 1.1*t*curveParameters[category].b + 2,
                    top: limb.element == 1 ? 1.25*t*curveParameters[category].a + 25 : (limb.element == 2 ? 0.6*t*curveParameters[category].a - 42 : 0.5*t*curveParameters[category].a - 53),
                    rotate: limb.element == 4 ? 6 : 6
                }
            },
            "78": {
                "1": {
                    left: 55,
                    rotate: 2.3*(pureValue - 65),
                    scale: 1
                }
            },
            "90": {
                "1": {
                    top: -570,
                    left: limb.element == 1 ? 12*t*curveParameters[category].b - 1550 : (limb.element == 2 ? 10.5*t*curveParameters[category].b - 1365 : 10*t*curveParameters[category].b - 1295),
                    rotate: 55,
                    scale: 1
                },
                "2": {
                    left: t*curveParameters[category].b - 9,
                    top: limb.element == 1 ? 1.3*t*curveParameters[category].a + 140 : (limb.element == 2 ? 0.65*t*curveParameters[category].a - 161 : (limb.element == 4 ? 0.55*t*curveParameters[category].a - 207 : 0.55*t*curveParameters[category].a - 205)), //!!!!!!!!!!!!!!!!!!!!!!!!!!
                    rotate: limb.element == 3 ? -10 : 0
                },
                "4": {
                    left: 1.35*1.45*90*curveParameters[category].b - 1,
                    top: limb.element == 1 ? 1.65*t*curveParameters[category].a + 175 : (limb.element == 2 ? 1.125*t*curveParameters[category].a + 55 : 0.65*t*curveParameters[category].a - 70),
                    rotate: 5
                }
            },
            "100": {
                "1": {
                    top: limb.element == 1 ? -560 : -550,
                    left: limb.element == 1 ? 7*t*curveParameters[category].b - 815 : 6*t*curveParameters[category].b - 680,
                    rotate: 45,
                    scale: 0.5
                },
                "2": {
                    top: limb.element == 1 ? -438 : (limb.element == 3 || limb.element == 4 ? -427 : -438),
                    left: limb.element == 1 ? 162 : 155,
                    scale: limb.element == 1 ? 0.45 : 0.5,
                    rotate: limb.element == 3 || limb.element == 4 ? -10 : 0
                },
                "3": {
                    top: limb.element == 1 ? -361 : (limb.element == 2 ? -350 : (limb.element == 4 ? -340 : -345)),
                    left: limb.element == 1 ? 146 : (limb.element == 4 ? 142 : 139),
                    scale: limb.element == 1 ? 0.58 : (limb.element == 4 ? 0.6 : 0.64),
                    rotate: limb.element == 3 ? -10 : 0
                },
                "4": {
                    top: limb.element == 1 ? -250 : (limb.element == 3 ? -249 : (limb.element == 4 ? -240 : -240)),
                    left: limb.element == 1 ? 125 : (limb.element == 3 ? 111 : (limb.element == 4 ? 118 : 122)),
                    rotate: limb.element == 3 || limb.element == 4 ? 0 : 5,
                    scale: limb.element == 1 ? 0.6 : (limb.element == 3 ? 0.75 : (limb.element == 4 ? 0.65 : 0.63))
                },
                "5": {
                    top: limb.element == 1 ? -185 : (limb.element == 2 ? -188 : (limb.element == 3 ? -180 : (limb.element == 4 ? -173 : -173))),
                    left: limb.element == 1 ? 108 : (limb.element == 2 ? 103 : (limb.element == 3 ? 95 :(limb.element == 4 ? 101 : 112))),
                    rotate: limb.element == 3 ? -1 : 5,
                    scale: limb.element == 1 ? 0.57 : (limb.element == 3 ? 0.67 : (limb.element == 4 ? 0.6 : 0.62))
                },
                "6": {
                    top: limb.element == 1 ? -76 : (limb.element == 2 ? -80 : (limb.element == 3 || limb.element == 4 ? -80 : -76)),
                    left: limb.element == 1 ? 88 : (limb.element == 2 ? 84 : (limb.element == 3 || limb.element == 4 ? 80 : 88)),
                    scale: limb.element == 1 ? 0.4 : (limb.element == 2 ? 0.48 : (limb.element == 3 ? 0.52 : (limb.element == 4 ? 0.49 : 0.4))),
                    rotate: limb.element == 3 ? -1 : 10
                },
                "7": {
                    top: limb.element == 1 ? -28 : (limb.element == 3 ? -29 : limb.element == 4 ? -25 : -29),
                    left: limb.element == 1 ? 63 : (limb.element == 3 ? 57 : limb.element == 4 ? 57 : 63),
                    scale: limb.element == 1 ? 0.47 : (limb.element == 3 ? 0.57 : (limb.element == 4 ? 0.57 : 0.53)),
                    rotate: limb.element == 1 || limb.element == 2 ? 10 : 0
                }
            }
        },
        "leftUpper": {
            "0": {
                "1": {
                    left: 1.35*t*curveParameters[category].b
                },
                "2": {
                    left: 1.5*t*curveParameters[category].b
                },
                "3": {
                    left: 1.35*t*curveParameters[category].b
                },
                "4": {
                    left: 1.35*t*curveParameters[category].b,
                    rotate: -pureValue/20
                },
                "5": {
                    left: 1.35*t*curveParameters[category].b,
                    rotate: -pureValue/20
                },
                "6": {
                    left: 1.2*t*curveParameters[category].b,
                    rotate: -pureValue/10
                },
                "7": {
                    left: 1.2*t*curveParameters[category].b,
                    rotate: limb.element == 1 ? -pureValue/20 : 0
                }
            },
            "45": {
                "1": {
                    left: 0.3*t*curveParameters[category].b - 70
                },
                "2": {
                    left: 0.3*t*curveParameters[category].b - 68
                }
            },
            "60": {
                "7": {
                    left: 1.2*t*curveParameters[category].b,
                    top: limb.element == 1 ? 1.4*t*curveParameters[category].a + 20 : (limb.element == 2 ? 0.9*t*curveParameters[category].a - 5 : 0.6*t*curveParameters[category].a - 21),
                    rotate: limb.element == 1 ? -pureValue/20 : 0
                }
            },
            "65": {
                "1": {
                    left: t*curveParameters[category].b + 5*(pureValue - 65),
                    rotate: -2.3*(pureValue - 65),
                    scale: 1
                },
                "3": {
                    top: limb.element == 1 ? 1.15*t*curveParameters[category].a + 44 : (limb.element == 2 ? 0.9*t*curveParameters[category].a - 24 : 0.8*t*curveParameters[category].a - 53),
                    left: 0.3*t*curveParameters[category].b - 67
                },
                "4": {
                    top: limb.element == 1 ? t*curveParameters[category].a : 0.65*t*curveParameters[category].a - 70,
                    left: 1.35*t*curveParameters[category].b,
                    rotate:-5
                },
                "5": {
                    top: limb.element == 1 ? 1.1*t*curveParameters[category].a + 15 : (limb.element == 2 ? 0.68*t*curveParameters[category].a - 50 : 0.55*t*curveParameters[category].a - 75),
                    left: limb.element == 1 ? 1.35*t*curveParameters[category].b : 1.1*t*curveParameters[category].b - 10,
                    rotate: -pureValue/20
                },
                "6": {
                    left: limb.element == 1 ? 1.2*t*curveParameters[category].b : 1.1*t*curveParameters[category].b - 2,
                    top: limb.element == 1 ? 1.25*t*curveParameters[category].a + 25 : (limb.element == 2 ? 0.6*t*curveParameters[category].a - 42 : 0.5*t*curveParameters[category].a - 53),
                    rotate: limb.element == 4 ? -6 : -6
                }
            },
            "78": {
                "1": {
                    left: -55,
                    rotate: -2.3*(pureValue - 65),
                    scale: 1
                }
            },
            "90": {
                "1": {
                    top: -570,
                    left: limb.element == 1 ? 12*t*curveParameters[category].b + 1550 : (limb.element == 2 ? 10.5*t*curveParameters[category].b + 1365 : 10*t*curveParameters[category].b + 1295),
                    rotate: -55,
                    scale: 1
                },
                "2": {
                    left: t*curveParameters[category].b + 9,
                    top: limb.element == 1 ? 1.3*t*curveParameters[category].a + 140 : (limb.element == 2 ? 0.65*t*curveParameters[category].a - 161 : (limb.element == 4 ? 0.55*t*curveParameters[category].a - 207 : 0.55*t*curveParameters[category].a - 205)),
                    rotate: limb.element == 3 ? 10 : 0
                },
                "4": {
                    left: 1.35*1.45*90*curveParameters[category].b + 1,
                    top: limb.element == 1 ? 1.65*t*curveParameters[category].a + 175 : (limb.element == 2 ? 1.125*t*curveParameters[category].a + 55 : 0.65*t*curveParameters[category].a - 70),
                    rotate: -5
                }
            },
            "100": {
                "1": {
                    top: limb.element == 1 ? -560 : -550,
                    left: limb.element == 1 ? 7*t*curveParameters[category].b + 888 : 6*t*curveParameters[category].b + 752,
                    rotate: -45,
                    scale: 0.5
                },
                "2": {
                    top: limb.element == 1 ? -438 : (limb.element == 3 || limb.element == 4 ? -425 : -438),
                    left: limb.element == 1 ? -85 : -85,
                    scale: limb.element == 1 ? 0.45 : 0.5,
                    rotate: limb.element == 3 || limb.element == 4 ? 10 : 0,
                },
                "3": {
                    top: limb.element == 1 ? -361 : (limb.element == 2 ? -350 : (limb.element == 4 ? -340 : -345)),
                    left: limb.element == 1 ? -87 : (limb.element == 2 ? -89 : (limb.element == 4 ? -85 : -88)),
                    scale: limb.element == 1 ? 0.58 : (limb.element == 4 ? 0.6 : 0.64),
                    rotate: limb.element == 3 ? 10 : 0
                },
                "4": {
                    top: limb.element == 1 ? -250 : (limb.element == 3 ? -249 : (limb.element == 4 ? -240 : -240)),
                    left: limb.element == 1 ? -70 : (limb.element == 3 ? -75 : (limb.element == 4 ? -68 : -67)),
                    rotate: limb.element == 3 || limb.element == 4 ? 0 : -5,
                    scale: limb.element == 1 ? 0.6 : (limb.element == 3 ? 0.75 : (limb.element == 4 ? 0.65 : 0.63))
                },
                "5": {
                    top: limb.element == 1 ? -185 : (limb.element == 2 ? -177 : (limb.element == 3 || limb.element == 4 ? -173 : -173)),
                    left: limb.element == 1 ? -45 : (limb.element == 2 ? -47 : (limb.element == 3 || limb.element == 4 ? -43 : -40)),
                    rotate: limb.element == 3 ? -1 : -5,
                    scale: limb.element == 1 ? 0.57 : (limb.element == 3 ? 0.67 : (limb.element == 4 ? 0.6 : 0.62))
                },
                "6": {
                    top: limb.element == 1 ? -76 : (limb.element == 2 ? -80 : (limb.element == 3 || limb.element == 4 ? -80 : -76)),
                    left: limb.element == 1 ? -3 : (limb.element == 2 ? -10 : (limb.element == 3 ? -11 : -8)),
                    scale: limb.element == 1 ? 0.4 : (limb.element == 2 ? 0.48 : (limb.element == 3 ? 0.52 : (limb.element == 4 ? 0.49 : 0.4))),
                    rotate: limb.element == 3 ? 1 : -10
                },
                "7": {
                    top: limb.element == 1 ? -28 : (limb.element == 3 ? -29 : limb.element == 4 ? -25 : -29),
                    left: limb.element == 1 ? 10 : (limb.element == 3 ? 4 : limb.element == 4 ? 3 : 5),
                    scale: limb.element == 1 ? 0.47 : (limb.element == 3 ? 0.57 : (limb.element == 4 ? 0.57 : 0.53)),
                    rotate: limb.element == 1 || limb.element == 2 ? -10 : 0
                }
            }
        },
        "rightLower": {
            "0": {
                "1": {
                    left: limb.element == 1 || limb.element == 2 || limb.element == 3 || limb.element == 4 ? -0.025*t : (limb.element == 3 || limb.element == 4 ? -1.25 : 0.17*t),
                },
                "2": {
                    left: limb.element == 1 || limb.element == 2 ? -0.025*t : (limb.element == 3 || limb.element == 4 ? -1.25 : 0.17*t),
                },
                "3": {
                    left: limb.element == 1 || limb.element == 2 ? -0.025*t : (limb.element == 3 || limb.element == 4 ? -1.25 : 0.17*t),
                },
                "4": {
                    left: limb.element == 1 || limb.element == 2 ? -0.025*t : (limb.element == 3 || limb.element == 4 ? -1.25 : 0.17*t),
                },
                "5": {
                    left: limb.element == 1 || limb.element == 2 ? -0.025*t : (limb.element == 3 || limb.element == 4 ? -1.25 : 0.17*t),
                },
                "6": {
                    left: limb.element == 1 || limb.element == 2 ? t*curveParameters[category].b : (limb.element == 3 || limb.element == 4 ? t*curveParameters[category].b + 3 : (limb.element == 4 ? -0.3*t : 0.17*t)),
                },
                "7": {
                    left: limb.element == 1 || limb.element == 2 ? t*curveParameters[category].b : (limb.element == 3 || limb.element == 4 ? t*curveParameters[category].b : (limb.element == 4 ? -0.8*t : 0.17*t)),
                }
            },
            "25": {
               "1": {
                    left: limb.element == 1 || limb.element == 3 || limb.element == 4 || limb.element == 2 ? -1.25 : limb.element == 2 ? -1.25 : 0.17*t,
                },
                "2": {
                    left: limb.element == 1 || limb.element == 3 || limb.element == 4 ? -1.25 : limb.element == 2 ? -1.25 : 0.17*t,
                },
                "3": {
                    left: limb.element == 1 || limb.element == 3 || limb.element == 4 ? -1.25 : limb.element == 2 ? -1.25 : 0.17*t,
                },
                "4": {
                    left: limb.element == 1 || limb.element == 3 || limb.element == 4 ? 0.1 : limb.element == 2 ? 1 : 0.17*t,
                },
                "5": {
                    left: limb.element == 1 || limb.element == 3 || limb.element == 4 ? 0.1 : (limb.element == 4 ? -0.2*t + 2  : limb.element == 2 ? -1 : 0.17*t),
                } 
            },
            "38": {
                "1": {
                    left: limb.element == 1 || limb.element == 3 || limb.element == 2 || limb.element == 4 ? 0.22*t - 31 : (limb.element == 2 ? 0.22*t - 31 : (limb.element == 4 ? 0.22*t - 32 : 0.17*t)),
                },
                "2": {
                    left: limb.element == 1 || limb.element == 3 || limb.element == 2 || limb.element == 4 ? 0.1*t - 14 : (limb.element == 2 ? 0.18*t - 26 : (limb.element == 4 ? 0.22*t - 35 : 0.17*t)),
                },
                "3": {
                    left: limb.element == 1 || limb.element == 3 || limb.element == 2 || limb.element == 4 ? 0.1*t - 11 : (limb.element == 2 ? 0.18*t - 26 : (limb.element == 4 ? 0.22*t - 35 : 0.17*t)),
                },
            },
            "47": {
                "1": {
                    left: limb.element == 1 || limb.element == 2 || limb.element == 3 || limb.element == 4 ? 0.17*t - 21 : (limb.element == 3 ? 0.05*t + 3 : (limb.element == 2 ? 0.05*t : (limb.element == 4 ? 0.22*t - 34 : 0.17*t))),
                },
            },
            "60": {
                "2": {
                    left: limb.element == 1 ? 0.18*t - 28 : (limb.element == 2 ? 0.18*t - 28 : (limb.element == 3 || limb.element == 4 ? 0.18*t -28 : (limb.element == 4 ? 0.22*t - 39 : 0.17*t))),
                },
                "4": {
                    left: limb.element == 1 ? 1 : (limb.element == 2 ? 1 : (limb.element == 3 || limb.element == 4 ? 1 : (limb.element == 4 ? -0.5*t + 48 : 0.17*t)))
                },
                "5": {
                    left: limb.element == 1 ? 1 : (limb.element == 2 ? 1 : (limb.element == 3 || limb.element == 4 ? 1 : (limb.element == 4 ? -0.2*t  : 0.17*t)))
                }
            },
            "75": {
                // "1": {
                //     left: limb.element == 1 || limb.element == 2 || limb.element == 3 || limb.element == 4 ? 0.3*t - 52 : (limb.element == 3 ? 0.72*t - 185 : (limb.element == 2 ? 0.05*t : (limb.element == 4 ? 0.22*t - 37 : 0.17*t))),
                // },
                "1": {
                    left: limb.element == 1 || limb.element == 2 || limb.element == 3 || limb.element == 4 ? 0.18*t - 24 : (limb.element == 3 ? 0.05*t + 3 : (limb.element == 2 ? 0.05*t : (limb.element == 4 ? 0.22*t - 34 : 0.17*t))),
                },
                "2": {
                    left: limb.element == 1 ? 0.18*t - 28 : (limb.element == 2 ? 0.18*t - 28 : (limb.element == 3 || limb.element == 4 ? 0.18*t -28 : (limb.element == 4 ? -0.2*t + 57 : 0.17*t)))
                },
                "3": {
                    left: limb.element == 1 ? 0.18*t - 28 : (limb.element == 2 ? 0.18*t - 28 : (limb.element == 3 || limb.element == 4 ? 0.18*t -28 : (limb.element == 4 ? -0.2*t + 50 : 0.17*t)))
                },
            },
            "80": {
                // "1": {
                //     left: limb.element == 1 || limb.element == 2 || limb.element == 3 || limb.element == 4 ? 0.3*t - 52 : (limb.element == 3 ? 0.65*t - 165 : (limb.element == 2 ? 0.9*t -250 : (limb.element == 4 ? 0.22*t - 37 : 0.17*t))),
                // },
            },
            "88": {
                // "1": {
                //     left: limb.element == 1 ? 40 : (limb.element == 2 ? 0.3*t - 52 : (limb.element == 3 ? 0.3*t - 52 : (limb.element == 4 ? 0.3*t - 52 : 0.17*t))),
                // },
                "2": {
                    left: limb.element == 1 ? 0.35*t - 78 : (limb.element == 2 ? 0.18*t - 26 : (limb.element == 3 || limb.element == 4 ? 0.18*t - 26 : (limb.element == 4 ? -0.2*t + 57 : 0.17*t))),
                },
                "3": {
                    left: limb.element == 1 ? 0.35*t - 65 : (limb.element == 2 ? 0.18*t - 26 : (limb.element == 3 || limb.element == 4 ? 0.18*t - 26 : (limb.element == 4 ? -0.2*t + 50 : 0.17*t))),
                },
                "4": {
                    left: limb.element == 1 ? 1 : (limb.element == 2 ? 1 : (limb.element == 3 || limb.element == 4 ? 1 : (limb.element == 4 ? -20 : 0.17*t))),
                },
                "5": {
                    left: limb.element == 1 ? 1 : (limb.element == 2 ? 1 : (limb.element == 3 || limb.element == 4 ? 1 : (limb.element == 4 ? -20 : 0.17*t))),
                }
            },
            "91": {
                "2": {
                    left: limb.element == 1 ? 0.35*t - 78 : (limb.element == 2 ? 0.18*t - 26 : (limb.element == 3 || limb.element == 4 ? 0.18*t - 26 : (limb.element == 4 ? 2 : 0.17*t))),
                },
                "3": {
                    left: limb.element == 1 ? 0.35*t - 65 : (limb.element == 2 ? 0.18*t - 26 : (limb.element == 3 || limb.element == 4 ? 0.18*t - 26 : (limb.element == 4 ? -2 : 0.17*t))),
                },
            },
            "97": {
                "1": {
                    left: limb.element == 1 || limb.element == 2 || limb.element == 3 || limb.element == 4 ? 42 : (limb.element == 2 ? -0.25*t + 135 : (limb.element == 3 ? 48 : (limb.element == 4 ? 30 : 0.17*t))),
                },
                "2": {
                    left: limb.element == 1 ? 0.35*t - 78 : (limb.element == 2 ? 0.18*t - 26 : (limb.element == 3 || limb.element == 4 ? 0.18*t - 26 : (limb.element == 4 ? 5 : 0.17*t)))
                },
                "3": {
                    left: limb.element == 1 ? 0.35*t - 65 : (limb.element == 2 ? 0.18*t - 26 : (limb.element == 3 || limb.element == 4 ? 0.18*t - 26 : (limb.element == 4 ? -2 : 0.17*t)))
                },
            },
            "100": {
                "1": {
                    top: limb.element == 1 || limb.element == 2 ? -630 : limb.element == 3 || limb.element == 4 ? -650 : limb.element == 4 ? -638 : -640,
                    left: limb.element == 1 || limb.element == 2 ? 100 : limb.element == 3 || limb.element == 4 ? 90 : limb.element == 4 ? 81 : 90,
                    rotate: 0,
                    scale: limb.element == 1 || limb.element == 2 ? 0.5 : limb.element == 3 ? 0.6 : limb.element == 4 ? 0.6 : 0.5
                },
                "2": {
                    top: limb.element == 1 || limb.element == 2 ? -544 : limb.element == 3 || limb.element == 4 ? -544 : limb.element == 4 ? -548 : -640,
                    left: limb.element == 1 || limb.element == 2 ? 60 : limb.element == 3 || limb.element == 4 ? 60 : limb.element == 4 ? 40 : 90,
                    rotate: 0,
                    scale: limb.element == 1 || limb.element == 2 ? 0.6 : limb.element == 3 ? 0.6 : limb.element == 4 ? 0.6 : 0.5
                },
                "3": {
                    top: limb.element == 1 || limb.element == 2 ? -415 : limb.element == 3 || limb.element == 4 ? -415 : limb.element == 4 ? -430 : -640,
                    left: limb.element == 1 || limb.element == 2 ? 57 : limb.element == 3 || limb.element == 4 ? 57 : limb.element == 4 ? 30 : 90,
                    rotate: 0,
                    scale: limb.element == 1 || limb.element == 2 ? 0.5 : limb.element == 3 || limb.element == 4 ? 0.5 : limb.element == 4 ? 0.6 : 90
                },
                "4": {
                    top: limb.element == 1 || limb.element == 2 ? -233 : limb.element == 3 || limb.element == 4 ? -233 : limb.element == 4 ? -245 : 90,
                    left: limb.element == 1 || limb.element == 2 ? 45 : limb.element == 3 || limb.element == 4 ? 45 : limb.element == 4 ? 20 : 90,
                    rotate: 0,
                    scale: limb.element == 1 || limb.element == 2 ? 0.5 : limb.element == 3 || limb.element == 4 ? 0.5 : limb.element == 4 ? 0.6 : 90
                },
                "5": {
                    top: limb.element == 1 || limb.element == 2 ? -144 : limb.element == 3 || limb.element == 4 ? -144 : limb.element == 4 ? -150 : 90,
                    left: limb.element == 1 || limb.element == 2 ? 45 : limb.element == 3 || limb.element == 4 ? 45 : limb.element == 4 ? 22 : 90,
                    rotate: 0,
                    scale: limb.element == 1 || limb.element == 2 ? 0.5 : limb.element == 3 || limb.element == 4 ? 0.5 : limb.element == 4 ? 0.56 : 90
                },
                "6": {
                    top: limb.element == 1 || limb.element == 2 ? -7 : limb.element == 3 || limb.element == 4 ? -7 : limb.element == 4 ? -33 : 90,
                    left: limb.element == 1 || limb.element == 2 ? 46 : limb.element == 3 || limb.element == 4 ? 46 : limb.element == 4 ? 15 : 90,
                    rotate: 0,
                    scale: limb.element == 1 || limb.element == 2 ? 0.45 : limb.element == 3 || limb.element == 4 ? 0.45 : limb.element == 4 ? 0.65 : 90
                },
                "7": {
                    top: limb.element == 1 || limb.element == 2 ? 18 : limb.element == 3 || limb.element == 4 ? 18 : limb.element == 4 ? -3 : 90,
                    left: limb.element == 1 || limb.element == 2 ? 38 : limb.element == 3 || limb.element == 4 ? 38 : limb.element == 4 ? 12 : 90,
                    rotate: 0,
                    scale: limb.element == 1 || limb.element == 2 ? 0.5 : limb.element == 3 || limb.element == 4 ? 0.5 : limb.element == 4 ? 0.65 : 90
                }
            }
        },
        "leftLower": {
            "0": {
                "1": {
                    left: limb.element == 1 || limb.element == 2 || limb.element == 3 || limb.element == 4 ? 0.025*t : (limb.element == 3 || limb.element == 4 ? 1.25 : -0.17*t),
                },
                "2": {
                    left: limb.element == 1 || limb.element == 2 ? 0.025*t : (limb.element == 3 || limb.element == 4 ? 1.25 : 0.17*t),
                },
                "3": {
                    left: limb.element == 1 || limb.element == 2 ? 0.025*t : (limb.element == 3 || limb.element == 4 ? 1.25 : 0.17*t),
                },
                "4": {
                    left: limb.element == 1 || limb.element == 2 ? 0.025*t : (limb.element == 3 || limb.element == 4 ? 1.25 : 0.17*t),
                },
                "5": {
                    left: limb.element == 1 || limb.element == 2 ? 0.025*t : (limb.element == 3 || limb.element == 4 ? 1.25 : 0.17*t),
                },
                "6": {
                    left: limb.element == 1 || limb.element == 2 ? t*curveParameters[category].b : (limb.element == 3 || limb.element == 4 ? t*curveParameters[category].b - 3 : (limb.element == 4 ? 0.3*t : 0.17*t)),
                },
                "7": {
                    left: limb.element == 1 || limb.element == 2 ? t*curveParameters[category].b : (limb.element == 3 || limb.element == 4 ? t*curveParameters[category].b : (limb.element == 4 ? 0.8*t : 0.17*t)),
                }
            },
            "25": {
                "1": {
                     left: limb.element == 1 || limb.element == 2 || limb.element == 3 || limb.element == 4 ? 1.25 : limb.element == 2 ? 1.25 : -0.17*t,
                 },
                 "2": {
                     left: limb.element == 1 || limb.element == 3 || limb.element == 4 ? 1.25 : limb.element == 2 ? 1.25 : 0.17*t,
                 },
                 "3": {
                     left: limb.element == 1 || limb.element == 3 || limb.element == 4 ? 1.25 : limb.element == 2 ? 1.25 : 0.17*t,
                 },
                 "4": {
                     left: limb.element == 1 || limb.element == 3 || limb.element == 4 ? -0.1 : limb.element == 2 ? -1 : 0.17*t,
                 },
                 "5": {
                     left: limb.element == 1 || limb.element == 3 || limb.element == 4 ? -0.1 : (limb.element == 4 ? 0.2*t - 2  : limb.element == 2 ? 1 : 0.17*t),
                 } 
             },
             "38": {
                 "1": {
                     left: limb.element == 1 || limb.element == 2 || limb.element == 3 || limb.element == 4 ? -0.22*t + 31 : (limb.element == 2 ? -0.22*t + 31 : (limb.element == 4 ? -0.22*t + 32 : 0.17*t)),
                 },
                 "2": {
                     left: limb.element == 1 || limb.element == 3 || limb.element == 2 || limb.element == 4 ? -0.1*t + 14 : (limb.element == 2 ? -0.18*t + 26 : (limb.element == 4 ? -0.22*t + 35 : 0.17*t)),
                 },
                 "3": {
                     left: limb.element == 1 || limb.element == 3 || limb.element == 2 || limb.element == 4 ? -0.1*t + 11 : (limb.element == 2 ? -0.18*t + 26 : (limb.element == 4 ? -0.22*t + 35 : 0.17*t)),
                 },
             },
             "47": {
                "1": {
                    left: limb.element == 1 || limb.element == 2 || limb.element == 3 || limb.element == 4 ? -0.17*t + 21 : (limb.element == 3 ? 0.05*t + 3 : (limb.element == 2 ? 0.05*t : (limb.element == 4 ? 0.22*t - 34 : 0.17*t))),
                },
             },
             "60": {
                 "2": {
                     left: limb.element == 1 ? -0.18*t + 28 : (limb.element == 2 ? -0.18*t + 28 : (limb.element == 3 || limb.element == 4 ? -0.18*t + 28 : (limb.element == 4 ? -0.22*t + 39 : 0.17*t))),
                     
                 },
                 "4": {
                     left: limb.element == 1 ? -1 : (limb.element == 2 ? -1 : (limb.element == 3 || limb.element == 4 ? -1 : (limb.element == 4 ? 0.5*t - 48 : 0.17*t)))
                 },
                 "5": {
                     left: limb.element == 1 ? -1 : (limb.element == 2 ? -1 : (limb.element == 3 || limb.element == 4 ? -1 : (limb.element == 4 ? 0.2*t : 0.17*t)))
                 }
             },
             "75": {
                "1": {
                    left: limb.element == 1 || limb.element == 2 || limb.element == 3 || limb.element == 4 ? -0.18*t + 24 : (limb.element == 3 ? 0.05*t + 3 : (limb.element == 2 ? 0.05*t : (limb.element == 4 ? 0.22*t - 34 : 0.17*t))),
                },
                "2": {
                    left: limb.element == 1 ? -0.18*t + 28 : (limb.element == 2 ? -0.18*t + 28 : (limb.element == 3 || limb.element == 4 ? -0.18*t + 28 : (limb.element == 4 ? 0.2*t - 57 : 0.17*t)))
                },
                "3": {
                    left: limb.element == 1 ? -0.18*t + 28 : (limb.element == 2 ? -0.18*t + 28 : (limb.element == 3 || limb.element == 4 ? -0.18*t + 28 : (limb.element == 4 ? 0.2*t - 50 : 0.17*t)))
                },
             },
             "80": {
                //  "1": {
                //      left: limb.element == 1 ? -0.3*t + 52 : (limb.element == 3 ? -0.65*t + 165 : (limb.element == 2 ? -0.9*t + 250 : (limb.element == 4 ? -0.22*t + 37 : 0.17*t))),
                //  },
             },
             "88": {
                //  "1": {
                //      left: limb.element == 1 ? -40 : (limb.element == 2 ? 0.25*t - 135 : (limb.element == 3 ? -0.17*t : (limb.element == 4 ? -34 : 0.17*t))),
                //  },
                 "2": {
                     left: limb.element == 1 ? -0.35*t + 78 : (limb.element == 2 ? -0.18*t + 26 : (limb.element == 3 || limb.element == 4 ? -0.18*t + 26 : (limb.element == 4 ? 0.2*t - 57 : 0.17*t))),
                 },
                 "3": {
                     left: limb.element == 1 ? -0.35*t + 65 : (limb.element == 2 ? -0.18*t + 26 : (limb.element == 3 || limb.element == 4 ? -0.18*t + 26 : (limb.element == 4 ? 0.2*t - 50 : 0.17*t))),
                 },
                 "4": {
                     left: limb.element == 1 ? -1 : (limb.element == 2 ? -1 : (limb.element == 3 || limb.element == 4 ? -1 : (limb.element == 4 ? 20 : 0.17*t))),
                 },
                 "5": {
                     left: limb.element == 1 ? -1 : (limb.element == 2 ? -1 : (limb.element == 3 || limb.element == 4 ? -1 : (limb.element == 4 ? 20 : 0.17*t))),
                 }
             },
             "91": {
                 "2": {
                     left: limb.element == 1 ? -0.35*t + 78 : (limb.element == 2 ? -0.18*t + 26 : (limb.element == 3 || limb.element == 4 ? -0.18*t + 26 : (limb.element == 4 ? -2 : 0.17*t))),
                 },
                 "3": {
                     left: limb.element == 1 ? -0.35*t + 65 : (limb.element == 2 ? -0.18*t + 26 : (limb.element == 3 || limb.element == 4 ? -0.18*t + 26 : (limb.element == 4 ? 2 : 0.17*t))),
                 },
             },
             "97": {
                 "1": {
                     left: limb.element == 1 || limb.element == 2 || limb.element == 3 || limb.element == 4 ? -42 : (limb.element == 2 ? 0.25*t - 135 : (limb.element == 3 ? -48 : (limb.element == 4 ? -30 : 0.17*t))),
                 },
                 "2": {
                     left: limb.element == 1 ? -0.35*t + 78 : (limb.element == 2 ? -0.18*t + 26 : (limb.element == 3 || limb.element == 4 ? -0.18*t + 26 : (limb.element == 4 ? -5 : 0.17*t)))
                 },
                 "3": {
                     left: limb.element == 1 ? -0.35*t + 65 : (limb.element == 2 ? -0.18*t + 26 : (limb.element == 3 || limb.element == 4 ? -0.18*t + 26 : (limb.element == 4 ? 2 : 0.17*t)))
                 },
             },
            "100": {
                "1": {
                    top: limb.element == 1 || limb.element == 2 ? -630 : limb.element == 3 || limb.element == 4 ? -650 : limb.element == 4 ? -638 : -640,
                    left: limb.element == 1 || limb.element == 2 ? -43 : limb.element == 3 || limb.element == 4 ? -53 : limb.element == 4 ? -36 : 90,
                    rotate: 0,
                    scale: limb.element == 1 || limb.element == 2 ? 0.5 : limb.element == 3 ? 0.6 : limb.element == 4 ? 0.6 : 0.5
                },
                "2": {
                    top: limb.element == 1 || limb.element == 2 ? -544 : limb.element == 3 || limb.element == 4 ? -544 : limb.element == 4 ? -550 : -640,
                    left: limb.element == 1 || limb.element == 2 ? -16 : limb.element == 3 || limb.element == 4 ? -16 : limb.element == 4 ? 4 : 90,
                    rotate: 0,
                    scale: limb.element == 1 || limb.element == 2 ? 0.6 : limb.element == 3 || limb.element == 4 ? 0.6 : limb.element == 4 ? 0.6 : 0.5
                },
                "3": {
                    top: limb.element == 1 || limb.element == 2 ? -415 : limb.element == 3 || limb.element == 4 ? -415 : limb.element == 4 ? -430 : -640,
                    left: limb.element == 1 || limb.element == 2 ? 4 : limb.element == 3 || limb.element == 4 ? 4 : limb.element == 4 ? 15 : 90,
                    rotate: 0,
                    scale: limb.element == 1 || limb.element == 2 ? 0.5 : limb.element == 3 || limb.element == 4 ? 0.5 : limb.element == 4 ? 0.6 : 90
                },
                "4": {
                    top: limb.element == 1 || limb.element == 2 ? -233 : limb.element == 3 || limb.element == 4 ? -233 : limb.element == 4 ? -245 : 90,
                    left: limb.element == 1 || limb.element == 2 ? 13 : limb.element == 3 || limb.element == 4 ? 13 : limb.element == 4 ? 27 : 90,
                    rotate: 0,
                    scale: limb.element == 1 || limb.element == 2 ? 0.5 : limb.element == 3 || limb.element == 4 ? 0.5 : limb.element == 4 ? 0.6 : 90
                },
                "5": {
                    top: limb.element == 1 || limb.element == 2 ? -144 : limb.element == 3 || limb.element == 4 ? -144 : limb.element == 4 ? -150 : 90,
                    left: limb.element == 1 || limb.element == 2 ? 13 : limb.element == 3 || limb.element == 4 ? 13 : limb.element == 4 ? 30 : 90,
                    rotate: 0,
                    scale: limb.element == 1 || limb.element == 2 ? 0.5 : limb.element == 3 || limb.element == 4 ? 0.5 : limb.element == 4 ? 0.56 : 90
                },
                "6": {
                    top: limb.element == 1 || limb.element == 2 ? -7 : limb.element == 3 || limb.element == 4 ? -7 : limb.element == 4 ? -33 : 90,
                    left: limb.element == 1 || limb.element == 2 ? 15 : limb.element == 3 || limb.element == 4 ? 15 : limb.element == 4 ? 22 : 90,
                    rotate: 0,
                    scale: limb.element == 1 || limb.element == 2 ? 0.45 : limb.element == 3 || limb.element == 4 ? 0.45 : limb.element == 4 ? 0.65 : 90
                },
                "7": {
                    top: limb.element == 1 || limb.element == 2 ? 18 : limb.element == 3 || limb.element == 4 ? 18 : limb.element == 4 ? -3 : 90,
                    left: limb.element == 1 || limb.element == 2 ? 18 : limb.element == 3 || limb.element == 4 ? 18 : limb.element == 4 ? 27 : 90,
                    rotate: 0,
                    scale: limb.element == 1 || limb.element == 2 ? 0.5 : limb.element == 3 || limb.element == 4 ? 0.5 : limb.element == 4 ? 0.65 : 90
                }
            }
        }
    }

    let nearestPureValue = 0;

    for(let k of Object.keys(specialCases[category]).reverse()){
        if(k <= parseInt(pureValue)){
            if(specialCases[category][k] && specialCases[category][k][ampLevel]){
                nearestPureValue = k;
                break;
            }
        }
    }

    console.log(nearestPureValue, pureValue);
    
    return {
        top: specialCases[category][nearestPureValue] && specialCases[category][nearestPureValue][ampLevel] && specialCases[category][nearestPureValue][ampLevel].top || t*curveParameters[category].a,
        left: specialCases[category][nearestPureValue] && specialCases[category][nearestPureValue][ampLevel] && specialCases[category][nearestPureValue][ampLevel].left || t*curveParameters[category].b,
        rotate: specialCases[category][nearestPureValue] && specialCases[category][nearestPureValue][ampLevel] && specialCases[category][nearestPureValue][ampLevel].rotate || 0,
        scale: specialCases[category][nearestPureValue] && specialCases[category][nearestPureValue][ampLevel] && specialCases[category][nearestPureValue][ampLevel].scale || 1
    }
}