import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import 'react-calendar/dist/Calendar.css';
import SuitableField from './SuitableField';
import generateInitialState from './generateInitialState';
import verifyAnswers from './verifyAnswers';
import ImageRules from './questions/imageRules.json';
import { changeStepForward, changeStepBackward, updateSelectedLimbs, updateSelectedImageCategory, setLimbVisibility, moveSelectedLimb, setUserId, setError, unsetError, changeSliderStatus } from './slices/surveySlice';
import { updateImage, resetImage } from './slices/humanImageSlice';
import { connect } from 'react-redux';
import CurveGenerator from './CurveGenerator'
import locateInSurveyStore from './locateInSurveyStore';
import moment from 'moment';
import convertToCSV from './convertToCSV';
import postData from './postData';

class FieldList extends React.Component{

    constructor(props){
        super(props);

        this.handleClick = this.handleClick.bind(this);
        this.handleBack = this.handleBack.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.setActive = this.setActive.bind(this);
        this.setNonActive = this.setNonActive.bind(this);
        this.setRootValue = this.setRootValue.bind(this);
        this.handleListChange = this.handleListChange.bind(this);
        this.setImageListId = this.setImageListId.bind(this);
        this.nextPage = this.nextPage.bind(this);
        
        let initialState = generateInitialState(props.questions);

        this.state = {
            ...initialState,
            allowedToProceed: false,
            freshList: true,
            defaults: {
                range: 0,
                date: moment().format('D MMMM YYYY')
            }
        };
    }

    setRootValue(value){
        let q = this.props.questions.find(q => q.type == "root");
        this.setState({
            freshList: true,
            [q.id]: value.value
        });
    }

    handleListChange(value){
        let q = this.props.questions.find(q => q.type == "imageList") || this.props.questions.find(q => q.type == "list");
        this.setState({
            [q.id]: value,
            freshList: true
        }, () => {
            if(q.type == "imageList"){
                let positionScreen = this.props.allQuestions[this.props.step + 1];
                let positionQuestion = positionScreen.find(q => q.type == "range");
                this.props.changeSliderStatus({
                    id: positionQuestion.id,
                    status: false
                })
                delete positionQuestion.answer;
            }
            if(q.type == "list"){
                let positionScreen = this.props.allQuestions[this.props.step + 3];
                let positionQuestion = positionScreen.find(q => q.type == "range");
                this.props.changeSliderStatus({
                    id: positionQuestion.id,
                    status: false
                })
                delete positionQuestion.answer;
                let imageListScreen = this.props.allQuestions[this.props.step + 2];
                let imageListQuestion = imageListScreen.find(q => q.type == "imageList");
                delete imageListQuestion.answer;
            }
        })
    }

    componentDidMount(){
        this.componentDidUpdate()
    }

    componentDidUpdate(){
        if(this.state.freshList){
            if(verifyAnswers(this.props.questions, this.state, this.props.selectedSliders)){
                this.setActive();
            }
            else{
                this.setNonActive();
            }
            this.setState({
                freshList: false
            });
        }
    }

    setActive(){
        this.setState({
            allowedToProceed: true
        });
    }

    setNonActive(){
        this.setState({
            allowedToProceed: false
        });
    }

    setCalendarId(id){
        this.calendarId = id;
    }

    setImageListId(id){
        this.setState({
            imageListId: id
        })
    }
    
    generateFields(questions){
        let fields = [];
        for(const q of questions){
            fields.push(
                <SuitableField 
                    question={q} 
                    handleListChange={this.handleListChange}
                    setImageListId={this.setImageListId}
                    handleChange={this.handleChange}
                    setRootValue={this.setRootValue}
                    elements={q.elements}
                    state={this.state}
                    key={q.id}
                    level="10"
                    nRange={this.state.nRange}
                />
            );
        }
        return fields;
    }

    saveAnswers(questions){
        for(let q of questions){
            let id = q.id;
            if(q.type == "row"){
                this.saveAnswers(q.elements);
            }
            if(q.type == "root" && this.state[q.id] == "yes"){
                this.saveAnswers(q.elements);
            }
            q.answer = this.state[id];
            if(q.type == "range" && !q.answer) q.answer = this.state.defaults.range;
            else if(q.type == "date" && !q.answer) q.answer = this.state.defaults.date;
        }
    }

    nextPage(){
        let q = this.props.questions.find(q => q.type == "root");
        if(q && q.answer == "no"){
            this.props.changeStepForward(q.jumpTo);
            this.setState({
                freshList: true
            });
            return;
        }
        if(this.props.questions.find(q => q.type == "imageList")){
            this.props.setLimbVisibility({
                step: this.props.step,
                display: true
            });
            let selectedImage = locateInSurveyStore(this.props.selectedImages, this.props.step);
            let selectedImageStep = Object.keys(this.props.selectedImages).find(key => this.props.selectedImages[key] === selectedImage);
            this.props.updateSelectedImageCategory({
                step: selectedImageStep,
                category: selectedImage.category + '/zoomedIn'
            });
        }
        this.props.changeStepForward(this.props.step + 1)
        this.setState({
            freshList: true
        });
    }

    handleClick(event){
        event.preventDefault();
        if(this.state.allowedToProceed){
            this.saveAnswers(this.props.questions);
            let userIdQuestion = this.props.questions.find(q => q.CSVName == "ID");
            if(userIdQuestion){
                postData('/auth.php', {'data': userIdQuestion.answer}).then(response => {
                    if(!response.validId){
                        this.props.setError({
                            fieldId: userIdQuestion.id,
                            message: "This user id is not valid"
                        });
                    }
                    else {
                        this.props.setUserId({ id: userIdQuestion.answer });
                        if(this.props.errors[userIdQuestion.id]) this.props.unsetError({
                            id: userIdQuestion.id
                        });
                        this.props.changeStepForward(this.props.step + 1);
                        this.setState({
                            freshList: true
                        });
                    }
                })
                // this.nextPage();
            }
            else this.nextPage();
        }
    }

    handleChange(event){
        this.setState({
            [event.target.id]: event.target.value,
            freshList: true,
            nRange: event.target.value
        }, () => {
            let rangeQ = this.props.questions.find(q => q.type == "range");
            if(!rangeQ){
                for(let q of this.props.questions){
                    rangeQ = q.elements && q.elements.find(q => q.type == "range");
                }
            }
            if(rangeQ){
                this.props.changeSliderStatus({ id: rangeQ.id, status: true });
            }
            let q = this.props.questions.find(q => q.id == event.target.id);
            if(q && q.imageModification){
                let level = locateInSurveyStore(this.props.selectedImages, this.props.step);
                let limb = locateInSurveyStore(this.props.selectedLimbs, this.props.step);
                let limbType = limb.element;
                console.log(limbType);
                let ampLevel = level.element;
                let ampCategory = level.category;
                let limbSpeed = ImageRules.limbPositionsSpeed[ampCategory][ampLevel];
                let limbPosition = {
                    position: CurveGenerator(limbSpeed*event.target.value, q.category, event.target.value, ampLevel, limb)
                };
                this.props.moveSelectedLimb({
                    step: this.props.step - 1,
                    ...limbPosition
                });
            }
        });
    }

    handleSubmit(event){
        event.preventDefault();
        this.handleClick(event);
    }

    handleBack(event){
        event.preventDefault();
        let rangeQ = this.props.questions.find(q => q.type == "range");
        if(!rangeQ){
            for(let q of this.props.questions){
                rangeQ = q.elements.find(q => q.type == "range");
            }
        }
        if(rangeQ && !rangeQ.hasOwnProperty('answer')){
            this.props.changeSliderStatus({ id: rangeQ.id, status: false });
        }
        let q = this.props.questions.find(q => q.imageModification == "true");
        if(q){
            let selectedImage = locateInSurveyStore(this.props.selectedImages, this.props.step);
            let selectedImageStep = Object.keys(this.props.selectedImages).find(key => this.props.selectedImages[key] === selectedImage);
            let currentCategory = selectedImage.category.split("/");
            this.props.updateSelectedImageCategory({
                step: selectedImageStep,
                category: [currentCategory[0], currentCategory[1]].join("/")
            });
            let level = locateInSurveyStore(this.props.selectedImages, this.props.step);
            let limb = locateInSurveyStore(this.props.selectedLimbs, this.props.step);
            let ampLevel = level.element;
            let ampCategory = level.category;
            let limbSpeed = ImageRules.limbPositionsSpeed[ampCategory][ampLevel];
            let limbPosition = {
                position: CurveGenerator(limbSpeed*(q.answer || 0), q.category, q.answer || 0, ampLevel, limb)
            };
            this.props.moveSelectedLimb({
                step: this.props.step - 1,
                ...limbPosition
            });
        }
        
        this.props.changeStepBackward();
    }

    render(){
        let button;
        let backButton;
        
        if(this.props.step == 1){
            backButton = (
                <Button style={{marginRight: 15}} variant="secondary" onClick={this.handleBack} disabled>Go back</Button>
            );
        }
        else{
            backButton = (
                <Button style={{marginRight: 15}} variant="secondary" onClick={this.handleBack}>Go back</Button>
            );
        }
        if(this.state.allowedToProceed){
            button = (
                <Button onClick={this.handleClick}>Next</Button>
            );
        }
        else{
            button = (
                <Button onClick={this.handleClick} disabled>Next</Button>
            );
        }
        return (
            <div>
                <Form onSubmit={this.handleSubmit}>
                    {this.generateFields(this.props.questions)}
                    {backButton}
                    {button}
                </Form>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeStepForward: (action) => dispatch(changeStepForward(action)),
        changeStepBackward: () => dispatch(changeStepBackward()),
        updateImage: (action) => dispatch(updateImage(action)),
        updateSelectedLimbs: (action) => dispatch(updateSelectedLimbs(action)),
        setLimbVisibility: (action) => dispatch(setLimbVisibility(action)),
        moveSelectedLimb: (action) => dispatch(moveSelectedLimb(action)),
        resetImage: () => dispatch(resetImage()),
        updateSelectedImageCategory: (action) => dispatch(updateSelectedImageCategory(action)),
        setUserId: (action) => dispatch(setUserId(action)),
        setError: (action) => dispatch(setError(action)),
        unsetError: (action) => dispatch(unsetError(action)),
        changeSliderStatus: (action) => dispatch(changeSliderStatus(action))
    }
};

const mapStateToProps = (state) => {
    return {
      step: state.survey.step,
      limb: state.humanImage.limb,
      selectedImages: state.survey.selectedImages,
      errors: state.survey.errors,
      selectedLimbs: state.survey.selectedLimbs,
      selectedSliders: state.survey.selectedSliders
    }
};
  
export default connect(mapStateToProps, mapDispatchToProps)(FieldList)