import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import SuitableField from './SuitableField';

export default class RootQuestion extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            value: props.answer || undefined,
            yes: (props.answer == "yes" ? "secondary" : "light"),
            no: props.answer ? (props.answer == "no" ? "secondary" : "light") : "light"
        }
        this.generateFields = this.generateFields.bind(this);
        this.displayElements = this.displayElements.bind(this);
        this.hideElements = this.hideElements.bind(this);
    }

    generateFields(questions){
        let fields = [];
        for(const q of questions){
            fields.push(
                <SuitableField
                    question={q} 
                    handleCalendarChange={this.props.handleCalendarChange}
                    handleImageListChange={this.props.handleImageListChange}
                    setCalendarId={this.props.setCalendarId}
                    setImageListId={this.props.setImageListId}
                    handleChange={this.props.handleChange} 
                    setAdditionalValue={this.props.setAdditionalValue}
                    state={this.props.state}
                    nRange={this.props.nRange}
                    key={q.id}
                />
            );
        }
        return fields;
    }

    displayElements(){
        this.setState({
            value: "yes",
            yes: "secondary",
            no: "light"
        }, () => {
            this.props.setRootValue({
                value: this.state.value,
                id: this.props.id
            });
        });
        
    }

    hideElements(){
        this.setState({
            value: "no",
            yes: "light",
            no: "secondary",
        }, () => {
            this.props.setRootValue({
                value: this.state.value,
                id: this.props.id
            });
        });
    }
    
    render(){
        return (
            <span>
                <Form.Row>
                    <Col><Button onClick={this.displayElements} variant={this.state.yes}>Yes</Button></Col>
                    <Col><Button onClick={this.hideElements} variant={this.state.no}>No</Button></Col>    
                </Form.Row>
                <br/>
                {this.state.value == "yes" ? this.generateFields(this.props.elements) : ''}
            </span>
        );
    }
}