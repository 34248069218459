import { createSlice } from '@reduxjs/toolkit'

export const humanImageSlice = createSlice({
    name: 'humanImage',
    initialState: {
        value: '0',
        category: 'humanImage'
    },
    reducers: {
        updateImage: (state, action) => {
            state.value = action.payload.element || '0';
            state.category = action.payload.category;
        },
        resetImage: state => {
            state.value = '0';
            state.category = 'humanImage';
        }
    }
})

export const {updateImage, resetImage} = humanImageSlice.actions;

export default humanImageSlice.reducer;