import React, { Component } from 'react';
import { connect } from 'react-redux';
import locateInSurveyStore from './locateInSurveyStore';
import ImageRules from './questions/imageRules.json';
import { updateImage, resetImage } from './slices/humanImageSlice';
import { setLimbVisibility } from './slices/surveySlice';
import jquery from 'jquery';

class HumanImage extends React.Component{
    pathToSvg = './svg';
    topOffset = 0;
    leftOffset = 0;

    constructor(props){
        super(props)
        if(ImageRules.screens[this.props.step]){
            let {category, element} = ImageRules.screens[this.props.step];
            this.image = require([this.pathToSvg, category, element].join('/') + '.svg').default
        }
        
        if(!ImageRules.questions[this.props.step+'.1']){
            let currentImage = locateInSurveyStore(this.props.selectedImages, this.props.step);
            this.props.updateImage(currentImage);
        }

        this.image = this.image || require([this.pathToSvg, this.props.category, this.props.image].join('/') + '.svg').default;
        this.limb = locateInSurveyStore(this.props.selectedLimbs, this.props.step);
        
        this.limbStep = Object.keys(this.props.selectedLimbs).find(key => this.props.selectedLimbs[key] === this.limb);
        
        if(ImageRules.limbVisibility[this.props.step]) this.props.setLimbVisibility({
            step: this.limbStep,
            display: false
        });

        if(ImageRules.limbVisibility[this.props.step + 1]) this.props.setLimbVisibility({
            step: this.limbStep,
            display: true
        });
        
        if(this.limb && this.props.selectedLimbs[this.limbStep]){
            this.limbSrc = require([this.pathToSvg, this.limb.category, this.limb.element].join('/') + '.svg').default
        }
        this.calculateLimbWidth = this.calculateLimbWidth.bind(this);
    }

    calculateLimbWidth(){
        if(this.limb.category == "rightUpper" || this.limb.category == "leftUpper"){
            return 140;
        }
        if(this.limb.category == "leftUpper/half" || this.limb.category == "rightUpper/half"){
            return 140;
        }
        return 110;
    }
    
    render(){
        let limb = '';
        if(this.limb && this.props.selectedLimbs[this.limbStep].display){
            this.limbScale = this.props.selectedLimbs[this.limbStep].position.scale;
            limb = (
                <img 
                    src={this.limbSrc} 
                    style={{top: this.topOffset + this.props.selectedLimbs[this.limbStep].position.top+'px', left: 
                    this.leftOffset + this.props.selectedLimbs[this.limbStep].position.left+'px',
                    transform: "rotate(" + this.props.selectedLimbs[this.limbStep].position.rotate + "deg) ", filter: ""}} 
                    width={this.calculateLimbWidth()*this.limbScale}
                    
                    id="limb">
                </img>
            );
        }
        return (
            <div>
                {this.props.labels ? 
                <div align="center" class="backgroundHuman" id={"layer1"} style={{backgroundImage: "url("+this.image+")", height:921, backgroundRepeat: "no-repeat", width:530, backgroundPosition: "center"}}></div>
                :
                <img src={this.image} id="layer1"></img>
                }
                
                {/*  */}
                {limb}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
      step: state.survey.step,
      selectedImages: state.survey.selectedImages,
      category: state.humanImage.category,
      image: state.humanImage.value,
      limb: state.humanImage.limb,
      selectedLimbs: state.survey.selectedLimbs,
      svgs: state.survey.svgs
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateImage: (action) => dispatch(updateImage(action)),
        resetImage: () => dispatch(resetImage()),
        setLimbVisibility: (action) => dispatch(setLimbVisibility(action))
    }
};
  
export default connect(mapStateToProps, mapDispatchToProps)(HumanImage)