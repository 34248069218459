import 'bootstrap/dist/css/bootstrap.min.css';
import './css/App.css';
import React, { useState } from 'react';
import LeftColumn from './LeftColumn';
import RightColumn from './RightColumn';
import Questions from './questions/questions.json';
import download from './Downloader';
import Labels from './questions/labels.json';
import prepareQuestions from './prepareQuestions';
import { connect } from 'react-redux';
import convertToCSV from './convertToCSV';
import postData from './postData';
import jquery from 'jquery';

class App extends React.Component {
  totalPages = Object.keys(Questions).length + 1;
  k = 0;
  constructor(props){
    super(props);
    
    this.state = {
      questions: Questions,
      labels: Labels,
      svgs: require.context ( './svg', true, /\.svg$/ ),
      key: 0
    };

    prepareQuestions(Questions);
    jquery(window).resize(function() {
    });
    
  }

  componentDidUpdate(){
    if(this.props.finished){
      postData('/savedata.php', { data: convertToCSV(Questions) }).then(response => {
        postData('/completed.php', { data: this.props.userId }).then(response => {
          console.log(response.text);
        })
      });
      let output = convertToCSV(Questions, 1);
    }
  }

  render(){
    this.k += 1;
    return (
      <div id="external-screen">
        {/* <div id="safari-fixer">
          </div> */}
        <div className="screen">
          <LeftColumn 
            label={this.state.labels[this.props.step]} 
            allQuestions={Questions} 
            questions={this.state.questions[this.props.step]}
            finished={this.props.finished}
            output={convertToCSV(Questions)}
          />
          {this.props.finished ? '' : <RightColumn key={this.k} svgs={this.state.svgs}/>}
        
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    step: state.survey.step,
    finished: state.survey.finished,
    totalPages: state.survey.totalPages,
    userId: state.survey.userId
  }
}

export default connect(mapStateToProps, null)(App)