export default function generateInitialState(questions){
    let initialState = {};
    for(let q of questions){
        if(q.type == "row" || q.type == "root"){
            let rowState = generateInitialState(q.elements);
            initialState = {
                ...initialState,
                ...rowState
            }
        }
        if(q.type == "root"){
            initialState[q.id] = q.answer || '';
        }
        else{
            initialState[q.id] = q.answer || '';
        }
    }
    return initialState;
}