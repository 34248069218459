let levelMap = {
    "Upper": {
        "1": "Shoulder",
        "2": "High above elbow",
        "3": "Above elbow",
        "4": "Below elbow",
        "5": "Above wrist",
        "6": "Wrist",
        "7": "Midhand"
    },
    "Lower": {
        "1": "Hip",
        "2": "High above knee",
        "3": "Above knee",
        "4": "Below knee",
        "5": "Low below knee",
        "6": "Ankle",
        "7": "Midfoot"
    }
};

let limbMap = {
    "Upper": {
        "1": "Open hand",
        "2": '"Hand D4, D5 bent"',
        "3": "Hand clenched over thumb",
        "4": "Hand clenched"
    },
    "Lower": {
        "1": "Foot and ankle",
        "2": "Foot",
        "3": "Toes",
        "4": "Big toe"
    }
};

let lastPart;
let lastLevel;
let lastLimb;

let Telescoped_Map = {
    "Upper": {
        "1": {
            "1": {
                "0": {
                    "from": 0,
                    "to": 0,
                    "description": "Normal length"
                },
                "20": {
                    "from": 17,
                    "to": 23,
                    "description": "Mid forearm"
                },
                "34": {
                    "from": 32,
                    "to": 38,
                    "description": "At elbow"
                },
                "54": {
                    "from": 51,
                    "to": 57,
                    "description": "Mid bicep"
                },
                "73": {
                    "from": 67,
                    "to": 80,
                    "description": "At deltoid"
                },
                "85": {
                    "from": 81,
                    "to": 90,
                    "description": "Attached to stump"
                },
                "94": {
                    "from": 91,
                    "to": 96,
                    "description": "Partially inside stump"
                },
                "98": {
                    "from": 97,
                    "to": 99,
                    "description": "Almost completely inside stump"
                },
                "100": "Completely telescoped"
            },
            "2": {
                "0": {
                    "from": 0,
                    "to": 0,
                    "description": "Normal length"
                },
                "20": {
                    "from": 17,
                    "to": 23,
                    "description": "Mid forearm"
                },
                "34": {
                    "from": 32,
                    "to": 38,
                    "description": "At elbow"
                },
                "54": {
                    "from": 51,
                    "to": 57,
                    "description": "Mid bicep"
                },
                "73": {
                    "from": 67,
                    "to": 79,
                    "description": "At deltoid"
                },
                "85": {
                    "from": 81,
                    "to": 90,
                    "description": "Attached to stump"
                },
                "94": {
                    "from": 91,
                    "to": 96,
                    "description": "Partially inside stump"
                },
                "98": {
                    "from": 97,
                    "to": 99,
                    "description": "Almost completely inside stump"
                },
                "100": "Completely telescoped"
            },
            "3": {
                "0": {
                    "from": 0,
                    "to": 0,
                    "description": "Normal length"
                },
                "20": {
                    "from": 17,
                    "to": 23,
                    "description": "Mid forearm"
                },
                "34": {
                    "from": 32,
                    "to": 38,
                    "description": "At elbow"
                },
                "54": {
                    "from": 51,
                    "to": 57,
                    "description": "Mid bicep"
                },
                "73": {
                    "from": 67,
                    "to": 79,
                    "description": "At deltoid"
                },
                "85": {
                    "from": 81,
                    "to": 90,
                    "description": "Attached to stump"
                },
                "94": {
                    "from": 91,
                    "to": 96,
                    "description": "Partially inside stump"
                },
                "98": {
                    "from": 97,
                    "to": 99,
                    "description": "Almost completely inside stump"
                },
                "100": "Completely telescoped"
            },
            "4": {
                "0": {
                    "from": 0,
                    "to": 0,
                    "description": "Normal length"
                },
                "20": {
                    "from": 17,
                    "to": 23,
                    "description": "Mid forearm"
                },
                "34": {
                    "from": 32,
                    "to": 38,
                    "description": "At elbow"
                },
                "54": {
                    "from": 51,
                    "to": 57,
                    "description": "Mid bicep"
                },
                "73": {
                    "from": 67,
                    "to": 79,
                    "description": "At deltoid"
                },
                "85": {
                    "from": 81,
                    "to": 90,
                    "description": "Attached to stump"
                },
                "94": {
                    "from": 91,
                    "to": 96,
                    "description": "Partially inside stump"
                },
                "98": {
                    "from": 97,
                    "to": 99,
                    "description": "Almost completely inside stump"
                },
                "100": "Completely telescoped"
            },
        },
        "2": {
            "1": {
                "0": {
                    "from": 0,
                    "to": 0,
                    "description": "Normal length"
                },
                "20": {
                    "from": 17,
                    "to": 23,
                    "description": "Mid forearm"
                },
                "41": {
                    "from": 39,
                    "to": 45,
                    "description": "At elbow"
                },
                "55": {
                    "from": 53,
                    "to": 59,
                    "description": "Mid bicep"
                },
                "68": {
                    "from": 66,
                    "to": 72,
                    "description": "Attached to stump"
                },
                "80": {
                    "from": 73,
                    "to": 96,
                    "description": "Partially inside stump"
                },
                "98": {
                    "from": 97,
                    "to": 99,
                    "description": "Almost completely inside stump"
                },
                "100": "Completely telescoped"
            },
            "2": {
                "0": {
                    "from": 0,
                    "to": 0,
                    "description": "Normal length"
                },
                "20": {
                    "from": 17,
                    "to": 23,
                    "description": "Mid forearm"
                },
                "41": {
                    "from": 39,
                    "to": 45,
                    "description": "At elbow"
                },
                "55": {
                    "from": 53,
                    "to": 59,
                    "description": "Mid bicep"
                },
                "68": {
                    "from": 66,
                    "to": 72,
                    "description": "Attached to stump"
                },
                "80": {
                    "from": 73,
                    "to": 96,
                    "description": "Partially inside stump"
                },
                "98": {
                    "from": 97,
                    "to": 99,
                    "description": "Almost completely inside stump"
                },
                "100": "Completely telescoped"
            },
            "3": {
                "0": {
                    "from": 0,
                    "to": 0,
                    "description": "Normal length"
                },
                "20": {
                    "from": 17,
                    "to": 23,
                    "description": "Mid forearm"
                },
                "41": {
                    "from": 39,
                    "to": 45,
                    "description": "At elbow"
                },
                "55": {
                    "from": 53,
                    "to": 59,
                    "description": "Mid bicep"
                },
                "68": {
                    "from": 66,
                    "to": 72,
                    "description": "Attached to stump"
                },
                "80": {
                    "from": 73,
                    "to": 96,
                    "description": "Partially inside stump"
                },
                "98": {
                    "from": 97,
                    "to": 99,
                    "description": "Almost completely inside stump"
                },
                "100": "Completely telescoped"
            },
            "4": {
                "0": {
                    "from": 0,
                    "to": 0,
                    "description": "Normal length"
                },
                "20": {
                    "from": 17,
                    "to": 23,
                    "description": "Mid forearm"
                },
                "41": {
                    "from": 39,
                    "to": 45,
                    "description": "At elbow"
                },
                "55": {
                    "from": 53,
                    "to": 59,
                    "description": "Mid bicep"
                },
                "68": {
                    "from": 66,
                    "to": 72,
                    "description": "Attached to stump"
                },
                "80": {
                    "from": 73,
                    "to": 96,
                    "description": "Partially inside stump"
                },
                "98": {
                    "from": 97,
                    "to": 99,
                    "description": "Almost completely inside stump"
                },
                "100": "Completely telescoped"
            },
        },
        "3": {
            "1": {
                "0": {
                    "from": 0,
                    "to": 0,
                    "description": "Normal length"
                },
                "20": {
                    "from": 17,
                    "to": 23,
                    "description": "Above wrist"
                },
                "41": {
                    "from": 39,
                    "to": 45,
                    "description": "Below elbow"
                },
                "60": {
                    "from": 57,
                    "to": 63,
                    "description": "Attached to stump"
                },
                "80": {
                    "from": 64,
                    "to": 96,
                    "description": "Partially inside stump"
                },
                "98": {
                    "from": 97,
                    "to": 99,
                    "description": "Almost completely inside stump"
                },
                "100": "Completely telescoped"
            },
            "2": {
                "0": {
                    "from": 0,
                    "to": 0,
                    "description": "Normal length"
                },
                "20": {
                    "from": 17,
                    "to": 23,
                    "description": "Above wrist"
                },
                "41": {
                    "from": 39,
                    "to": 45,
                    "description": "Below elbow"
                },
                "60": {
                    "from": 57,
                    "to": 63,
                    "description": "Attached to stump"
                },
                "80": {
                    "from": 64,
                    "to": 96,
                    "description": "Partially inside stump"
                },
                "98": {
                    "from": 97,
                    "to": 99,
                    "description": "Almost completely inside stump"
                },
                "100": "Completely telescoped"
            },
            "3": {
                "0": {
                    "from": 0,
                    "to": 0,
                    "description": "Normal length"
                },
                "20": {
                    "from": 17,
                    "to": 23,
                    "description": "Above wrist"
                },
                "41": {
                    "from": 39,
                    "to": 45,
                    "description": "Below elbow"
                },
                "60": {
                    "from": 57,
                    "to": 63,
                    "description": "Attached to stump"
                },
                "80": {
                    "from": 64,
                    "to": 96,
                    "description": "Partially inside stump"
                },
                "98": {
                    "from": 97,
                    "to": 99,
                    "description": "Almost completely inside stump"
                },
                "100": "Completely telescoped"
            },
            "4": {
                "0": {
                    "from": 0,
                    "to": 0,
                    "description": "Normal length"
                },
                "20": {
                    "from": 17,
                    "to": 23,
                    "description": "Above wrist"
                },
                "41": {
                    "from": 39,
                    "to": 45,
                    "description": "Below elbow"
                },
                "60": {
                    "from": 57,
                    "to": 63,
                    "description": "Attached to stump"
                },
                "80": {
                    "from": 64,
                    "to": 96,
                    "description": "Partially inside stump"
                },
                "98": {
                    "from": 97,
                    "to": 99,
                    "description": "Almost completely inside stump"
                },
                "100": "Completely telescoped"
            },
        },
        "4": {
            "1": {
                "0": {
                    "from": 0,
                    "to": 0,
                    "description": "Normal length"
                },
                "25": {
                    "from": 22,
                    "to": 28,
                    "description": "Mid forearm"
                },
                "47": {
                    "from": 44,
                    "to": 50,
                    "description": "Attached to stump"
                },
                "70": {
                    "from": 51,
                    "to": 96,
                    "description": "Partially inside stump"
                },
                "98": {
                    "from": 97,
                    "to": 99,
                    "description": "Almost completely inside stump"
                },
                "100": "Completely telescoped"
            },
            "2": {
                "0": {
                    "from": 0,
                    "to": 0,
                    "description": "Normal length"
                },
                "25": {
                    "from": 22,
                    "to": 28,
                    "description": "Mid forearm"
                },
                "47": {
                    "from": 44,
                    "to": 50,
                    "description": "Attached to stump"
                },
                "70": {
                    "from": 51,
                    "to": 96,
                    "description": "Partially inside stump"
                },
                "98": {
                    "from": 97,
                    "to": 99,
                    "description": "Almost completely inside stump"
                },
                "100": "Completely telescoped"
            },
            "3": {
                "0": {
                    "from": 0,
                    "to": 0,
                    "description": "Normal length"
                },
                "25": {
                    "from": 22,
                    "to": 28,
                    "description": "Mid forearm"
                },
                "47": {
                    "from": 44,
                    "to": 50,
                    "description": "Attached to stump"
                },
                "70": {
                    "from": 51,
                    "to": 96,
                    "description": "Partially inside stump"
                },
                "98": {
                    "from": 97,
                    "to": 99,
                    "description": "Almost completely inside stump"
                },
                "100": "Completely telescoped"
            },
            "4": {
                "0": {
                    "from": 0,
                    "to": 0,
                    "description": "Normal length"
                },
                "25": {
                    "from": 22,
                    "to": 28,
                    "description": "Mid forearm"
                },
                "47": {
                    "from": 44,
                    "to": 50,
                    "description": "Attached to stump"
                },
                "70": {
                    "from": 51,
                    "to": 96,
                    "description": "Partially inside stump"
                },
                "98": {
                    "from": 97,
                    "to": 99,
                    "description": "Almost completely inside stump"
                },
                "100": "Completely telescoped"
            },
        },
        "5": {
            "1": {
                "0": {
                    "from": 0,
                    "to": 0,
                    "description": "Normal length"
                },
                "16": {
                    "from": 13,
                    "to": 19,
                    "description": "Above wrist"
                },
                "35": {
                    "from": 30,
                    "to": 40,
                    "description": "Attached to stump"
                },
                "70": {
                    "from": 41,
                    "to": 96,
                    "description": "Partially inside stump"
                },
                "98": {
                    "from": 97,
                    "to": 99,
                    "description": "Almost completely inside stump"
                },
                "100": "Completely telescoped"
            },
            "2": {
                "0": {
                    "from": 0,
                    "to": 0,
                    "description": "Normal length"
                },
                "16": {
                    "from": 13,
                    "to": 19,
                    "description": "Above wrist"
                },
                "35": {
                    "from": 30,
                    "to": 40,
                    "description": "Attached to stump"
                },
                "70": {
                    "from": 41,
                    "to": 96,
                    "description": "Partially inside stump"
                },
                "98": {
                    "from": 97,
                    "to": 99,
                    "description": "Almost completely inside stump"
                },
                "100": "Completely telescoped"
            },
            "3": {
                "0": {
                    "from": 0,
                    "to": 0,
                    "description": "Normal length"
                },
                "16": {
                    "from": 13,
                    "to": 19,
                    "description": "Above wrist"
                },
                "35": {
                    "from": 30,
                    "to": 40,
                    "description": "Attached to stump"
                },
                "70": {
                    "from": 41,
                    "to": 96,
                    "description": "Partially inside stump"
                },
                "98": {
                    "from": 97,
                    "to": 99,
                    "description": "Almost completely inside stump"
                },
                "100": "Completely telescoped"
            },
            "4": {
                "0": {
                    "from": 0,
                    "to": 0,
                    "description": "Normal length"
                },
                "16": {
                    "from": 13,
                    "to": 19,
                    "description": "Above wrist"
                },
                "35": {
                    "from": 30,
                    "to": 40,
                    "description": "Attached to stump"
                },
                "70": {
                    "from": 41,
                    "to": 96,
                    "description": "Partially inside stump"
                },
                "98": {
                    "from": 97,
                    "to": 99,
                    "description": "Almost completely inside stump"
                },
                "100": "Completely telescoped"
            },
        },
        "6": {
            "1": {
                "0": {
                    "from": 0,
                    "to": 0,
                    "description": "Normal length"
                },
                "4": {
                    "from": 1,
                    "to": 6,
                    "description": "Attached to stump"
                },
                "45": {
                    "from": 7,
                    "to": 96,
                    "description": "Partially inside stump"
                },
                "98": {
                    "from": 97,
                    "to": 99,
                    "description": "Almost completely inside stump"
                },
                "100": "Completely telescoped"
            },
            "2": {
                "0": {
                    "from": 0,
                    "to": 0,
                    "description": "Normal length"
                },
                "4": {
                    "from": 1,
                    "to": 6,
                    "description": "Attached to stump"
                },
                "45": {
                    "from": 7,
                    "to": 96,
                    "description": "Partially inside stump"
                },
                "98": {
                    "from": 97,
                    "to": 99,
                    "description": "Almost completely inside stump"
                },
                "100": "Completely telescoped"
            },
            "3": {
                "0": {
                    "from": 0,
                    "to": 0,
                    "description": "Normal length"
                },
                "4": {
                    "from": 1,
                    "to": 6,
                    "description": "Attached to stump"
                },
                "45": {
                    "from": 7,
                    "to": 96,
                    "description": "Partially inside stump"
                },
                "98": {
                    "from": 97,
                    "to": 99,
                    "description": "Almost completely inside stump"
                },
                "100": "Completely telescoped"
            },
            "4": {
                "0": {
                    "from": 0,
                    "to": 0,
                    "description": "Normal length"
                },
                "4": {
                    "from": 1,
                    "to": 6,
                    "description": "Attached to stump"
                },
                "45": {
                    "from": 7,
                    "to": 96,
                    "description": "Partially inside stump"
                },
                "98": {
                    "from": 97,
                    "to": 99,
                    "description": "Almost completely inside stump"
                },
                "100": "Completely telescoped"
            },
        },
        "7": {
            "1": {
                "0": {
                    "from": 0,
                    "to": 0,
                    "description": "Normal length"
                },
                "10": {
                    "from": 1,
                    "to": 20,
                    "description": "Attached to stump"
                },
                "55": {
                    "from": 21,
                    "to": 96,
                    "description": "Partially inside stump"
                },
                "98": {
                    "from": 97,
                    "to": 99,
                    "description": "Almost completely inside stump"
                },
                "100": "Completely telescoped"
            },
            "2": {
                "0": {
                    "from": 0,
                    "to": 0,
                    "description": "Normal length"
                },
                "10": {
                    "from": 1,
                    "to": 20,
                    "description": "Attached to stump"
                },
                "55": {
                    "from": 21,
                    "to": 96,
                    "description": "Partially inside stump"
                },
                "98": {
                    "from": 97,
                    "to": 99,
                    "description": "Almost completely inside stump"
                },
                "100": "Completely telescoped"
            },
            "3": {
                "0": {
                    "from": 0,
                    "to": 0,
                    "description": "Normal length"
                },
                "10": {
                    "from": 1,
                    "to": 20,
                    "description": "Attached to stump"
                },
                "55": {
                    "from": 21,
                    "to": 96,
                    "description": "Partially inside stump"
                },
                "98": {
                    "from": 97,
                    "to": 99,
                    "description": "Almost completely inside stump"
                },
                "100": "Completely telescoped"
            },
            "4": {
                "0": {
                    "from": 0,
                    "to": 0,
                    "description": "Normal length"
                },
                "10": {
                    "from": 1,
                    "to": 20,
                    "description": "Attached to stump"
                },
                "55": {
                    "from": 21,
                    "to": 96,
                    "description": "Partially inside stump"
                },
                "98": {
                    "from": 97,
                    "to": 99,
                    "description": "Almost completely inside stump"
                },
                "100": "Completely telescoped"
            }
        }
    },
    "Lower": {
        "1": {
            "1": {
                "0": {
                    "from": 0,
                    "to": 0,
                    "description": "Normal length"
                },
                "20": {
                    "from": 17,
                    "to": 23,
                    "description": "Mid leg"
                },
                "37": {
                    "from": 35,
                    "to": 41,
                    "description": "At knee"
                },
                "62": {
                    "from": 59,
                    "to": 65,
                    "description": "Mid thigh"
                },
                "74": {
                    "from": 71,
                    "to": 77,
                    "description": "High thigh"
                },
                "84": {
                    "from": 81,
                    "to": 87,
                    "description": "Attached to stump"
                },
                "92": {
                    "from": 88,
                    "to": 96,
                    "description": "Partially inside stump"
                },
                "98": {
                    "from": 97,
                    "to": 99,
                    "description": "Almost completely inside stump"
                },
                "100": "Completely telescoped"
            },
            "2": {
                "0": {
                    "from": 0,
                    "to": 0,
                    "description": "Normal length"
                },
                "26": {
                    "from": 23,
                    "to": 29,
                    "description": "Mid leg"
                },
                "44": {
                    "from": 41,
                    "to": 47,
                    "description": "At knee"
                },
                "68": {
                    "from": 65,
                    "to": 71,
                    "description": "Mid thigh"
                },
                "80": {
                    "from": 77,
                    "to": 83,
                    "description": "High thigh"
                },
                "90": {
                    "from": 87,
                    "to": 93,
                    "description": "Attached to stump"
                },
                "95": {
                    "from": 94,
                    "to": 96,
                    "description": "Partially inside stump"
                },
                "98": {
                    "from": 97,
                    "to": 99,
                    "description": "Almost completely inside stump"
                },
                "100": "Completely telescoped"
            },
            "3": {
                "0": {
                    "from": 0,
                    "to": 0,
                    "description": "Normal length"
                },
                "30": {
                    "from": 27,
                    "to": 33,
                    "description": "Mid leg"
                },
                "47": {
                    "from": 45,
                    "to": 51,
                    "description": "At knee"
                },
                "72": {
                    "from": 69,
                    "to": 75,
                    "description": "Mid thigh"
                },
                "84": {
                    "from": 81,
                    "to": 87,
                    "description": "High thigh"
                },
                "92": {
                    "from": 91,
                    "to": 93,
                    "description": "Attached to stump"
                },
                "95": {
                    "from": 94,
                    "to": 96,
                    "description": "Partially inside stump"
                },
                "98": {
                    "from": 97,
                    "to": 99,
                    "description": "Almost completely inside stump"
                },
                "100": "Completely telescoped"
            },
            "4": {
                "0": {
                    "from": 0,
                    "to": 0,
                    "description": "Normal length"
                },
                "30": {
                    "from": 27,
                    "to": 33,
                    "description": "Mid leg"
                },
                "47": {
                    "from": 45,
                    "to": 51,
                    "description": "At knee"
                },
                "72": {
                    "from": 69,
                    "to": 75,
                    "description": "Mid thigh"
                },
                "84": {
                    "from": 81,
                    "to": 87,
                    "description": "High thigh"
                },
                "92": {
                    "from": 91,
                    "to": 93,
                    "description": "Attached to stump"
                },
                "95": {
                    "from": 94,
                    "to": 96,
                    "description": "Partially inside stump"
                },
                "98": {
                    "from": 97,
                    "to": 99,
                    "description": "Almost completely inside stump"
                },
                "100": "Completely telescoped"
            },
        },
        "2": {
            "1": {
                "0": {
                    "from": 0,
                    "to": 0,
                    "description": "Normal length"
                },
                "22": {
                    "from": 19,
                    "to": 25,
                    "description": "Mid leg"
                },
                "44": {
                    "from": 41,
                    "to": 46,
                    "description": "At knee"
                },
                "72": {
                    "from": 68,
                    "to": 74,
                    "description": "Mid thigh"
                },
                "83": {
                    "from": 80,
                    "to": 86,
                    "description": "Attached to stump"
                },
                "92": {
                    "from": 87,
                    "to": 96,
                    "description": "Partially inside stump"
                },
                "98": {
                    "from": 97,
                    "to": 99,
                    "description": "Almost completely inside stump"
                },
                "100": "Completely telescoped"
            },
            "2": {
                "0": {
                    "from": 0,
                    "to": 0,
                    "description": "Normal length"
                },
                "29": {
                    "from": 25,
                    "to": 32,
                    "description": "Mid leg"
                },
                "50": {
                    "from": 47,
                    "to": 52,
                    "description": "At knee"
                },
                "78": {
                    "from": 74,
                    "to": 80,
                    "description": "Mid thigh"
                },
                "90": {
                    "from": 87,
                    "to": 93,
                    "description": "Attached to stump"
                },
                "95": {
                    "from": 94,
                    "to": 96,
                    "description": "Partially inside stump"
                },
                "98": {
                    "from": 97,
                    "to": 99,
                    "description": "Almost completely inside stump"
                },
                "100": "Completely telescoped"
            },
            "3": {
                "0": {
                    "from": 0,
                    "to": 0,
                    "description": "Normal length"
                },
                "35": {
                    "from": 31,
                    "to": 38,
                    "description": "Mid leg"
                },
                "55": {
                    "from": 53,
                    "to": 58,
                    "description": "At knee"
                },
                "83": {
                    "from": 80,
                    "to": 86,
                    "description": "Mid thigh"
                },
                "93": {
                    "from": 92,
                    "to": 94,
                    "description": "Attached to stump"
                },
                "96": {
                    "from": 95,
                    "to": 96,
                    "description": "Partially inside stump"
                },
                "98": {
                    "from": 97,
                    "to": 99,
                    "description": "Almost completely inside stump"
                },
                "100": "Completely telescoped"
            },
            "4": {
                "0": {
                    "from": 0,
                    "to": 0,
                    "description": "Normal length"
                },
                "35": {
                    "from": 31,
                    "to": 38,
                    "description": "Mid leg"
                },
                "55": {
                    "from": 53,
                    "to": 58,
                    "description": "At knee"
                },
                "83": {
                    "from": 80,
                    "to": 86,
                    "description": "Mid thigh"
                },
                "93": {
                    "from": 92,
                    "to": 94,
                    "description": "Attached to stump"
                },
                "96": {
                    "from": 95,
                    "to": 96,
                    "description": "Partially inside stump"
                },
                "98": {
                    "from": 97,
                    "to": 99,
                    "description": "Almost completely inside stump"
                },
                "100": "Completely telescoped"
            }
        },
        "3": {
            "1": {
                "0": {
                    "from": 0,
                    "to": 0,
                    "description": "Normal length"
                },
                "29": {
                    "from": 26,
                    "to": 32,
                    "description": "Mid leg"
                },
                "55": {
                    "from": 52,
                    "to": 58,
                    "description": "At knee"
                },
                "78": {
                    "from": 76,
                    "to": 82,
                    "description": "Attached to stump"
                },
                "92": {
                    "from": 83,
                    "to": 96,
                    "description": "Partially inside stump"
                },
                "98": {
                    "from": 97,
                    "to": 99,
                    "description": "Almost completely inside stump"
                },
                "100": "Completely telescoped"
            },
            "2": {
                "0": {
                    "from": 0,
                    "to": 0,
                    "description": "Normal length"
                },
                "37": {
                    "from": 33,
                    "to": 42,
                    "description": "Mid leg"
                },
                "64": {
                    "from": 59,
                    "to": 68,
                    "description": "At knee"
                },
                "88": {
                    "from": 85,
                    "to": 91,
                    "description": "Attached to stump"
                },
                "94": {
                    "from": 92,
                    "to": 96,
                    "description": "Partially inside stump"
                },
                "98": {
                    "from": 97,
                    "to": 99,
                    "description": "Almost completely inside stump"
                },
                "100": "Completely telescoped"
            },
            "3": {
                "0": {
                    "from": 0,
                    "to": 0,
                    "description": "Normal length"
                },
                "45": {
                    "from": 40,
                    "to": 49,
                    "description": "Mid leg"
                },
                "70": {
                    "from": 66,
                    "to": 75,
                    "description": "At knee"
                },
                "92": {
                    "from": 91,
                    "to": 93,
                    "description": "Attached to stump"
                },
                "95": {
                    "from": 94,
                    "to": 96,
                    "description": "Partially inside stump"
                },
                "98": {
                    "from": 97,
                    "to": 99,
                    "description": "Almost completely inside stump"
                },
                "100": "Completely telescoped"
            },
            "4": {
                "0": {
                    "from": 0,
                    "to": 0,
                    "description": "Normal length"
                },
                "45": {
                    "from": 40,
                    "to": 49,
                    "description": "Mid leg"
                },
                "70": {
                    "from": 66,
                    "to": 75,
                    "description": "At knee"
                },
                "92": {
                    "from": 91,
                    "to": 93,
                    "description": "Attached to stump"
                },
                "95": {
                    "from": 94,
                    "to": 96,
                    "description": "Partially inside stump"
                },
                "98": {
                    "from": 97,
                    "to": 99,
                    "description": "Almost completely inside stump"
                },
                "100": "Completely telescoped"
            }
        },
        "4": {
            "1": {
                "0": {
                    "from": 0,
                    "to": 0,
                    "description": "Normal length"
                },
                "48": {
                    "from": 41,
                    "to": 54,
                    "description": "Mid leg"
                },
                "66": {
                    "from": 61,
                    "to": 71,
                    "description": "Attached to stump"
                },
                "80": {
                    "from": 72,
                    "to": 96,
                    "description": "Partially inside stump"
                },
                "98": {
                    "from": 97,
                    "to": 99,
                    "description": "Almost completely inside stump"
                },
                "100": "Completely telescoped"
            },
            "2": {
                "0": {
                    "from": 0,
                    "to": 0,
                    "description": "Normal length"
                },
                "63": {
                    "from": 56,
                    "to": 69,
                    "description": "Mid leg"
                },
                "80": {
                    "from": 77,
                    "to": 83,
                    "description": "Attached to stump"
                },
                "90": {
                    "from": 84,
                    "to": 96,
                    "description": "Partially inside stump"
                },
                "98": {
                    "from": 97,
                    "to": 99,
                    "description": "Almost completely inside stump"
                },
                "100": "Completely telescoped"
            },
            "3": {
                "0": {
                    "from": 0,
                    "to": 0,
                    "description": "Normal length"
                },
                "71": {
                    "from": 66,
                    "to": 78,
                    "description": "Mid leg"
                },
                "90": {
                    "from": 87,
                    "to": 93,
                    "description": "Attached to stump"
                },
                "95": {
                    "from": 94,
                    "to": 96,
                    "description": "Partially inside stump"
                },
                "98": {
                    "from": 97,
                    "to": 99,
                    "description": "Almost completely inside stump"
                },
                "100": "Completely telescoped"
            },
            "4": {
                "0": {
                    "from": 0,
                    "to": 0,
                    "description": "Normal length"
                },
                "71": {
                    "from": 66,
                    "to": 78,
                    "description": "Mid leg"
                },
                "90": {
                    "from": 87,
                    "to": 93,
                    "description": "Attached to stump"
                },
                "95": {
                    "from": 94,
                    "to": 96,
                    "description": "Partially inside stump"
                },
                "98": {
                    "from": 97,
                    "to": 99,
                    "description": "Almost completely inside stump"
                },
                "100": "Completely telescoped"
            }
        },
        "5": {
            "1": {
                "0": {
                    "from": 0,
                    "to": 0,
                    "description": "Normal length"
                },
                "20": {
                    "from": 12,
                    "to": 32,
                    "description": "Above ankle"
                },
                "48": {
                    "from": 42,
                    "to": 56,
                    "description": "Attached to stump"
                },
                "80": {
                    "from": 57,
                    "to": 96,
                    "description": "Partially inside stump"
                },
                "98": {
                    "from": 97,
                    "to": 99,
                    "description": "Almost completely inside stump"
                },
                "100": "Completely telescoped"
            },
            "2": {
                "0": {
                    "from": 0,
                    "to": 0,
                    "description": "Normal length"
                },
                "20": {
                    "from": 34,
                    "to": 54,
                    "description": "Above ankle"
                },
                "70": {
                    "from": 65,
                    "to": 75,
                    "description": "Attached to stump"
                },
                "85": {
                    "from": 76,
                    "to": 96,
                    "description": "Partially inside stump"
                },
                "98": {
                    "from": 97,
                    "to": 99,
                    "description": "Almost completely inside stump"
                },
                "100": "Completely telescoped"
            },
            "3": {
                "0": {
                    "from": 0,
                    "to": 0,
                    "description": "Normal length"
                },
                "60": {
                    "from": 50,
                    "to": 70,
                    "description": "Above ankle"
                },
                "86": {
                    "from": 80,
                    "to": 93,
                    "description": "Attached to stump"
                },
                "95": {
                    "from": 94,
                    "to": 96,
                    "description": "Partially inside stump"
                },
                "98": {
                    "from": 97,
                    "to": 99,
                    "description": "Almost completely inside stump"
                },
                "100": "Completely telescoped"
            },
            "4": {
                "0": {
                    "from": 0,
                    "to": 0,
                    "description": "Normal length"
                },
                "60": {
                    "from": 50,
                    "to": 70,
                    "description": "Above ankle"
                },
                "86": {
                    "from": 80,
                    "to": 93,
                    "description": "Attached to stump"
                },
                "95": {
                    "from": 94,
                    "to": 96,
                    "description": "Partially inside stump"
                },
                "98": {
                    "from": 97,
                    "to": 99,
                    "description": "Almost completely inside stump"
                },
                "100": "Completely telescoped"
            }
        },
        "6": {
            "1": {
                
            },
            "2": {
                "0": {
                    "from": 0,
                    "to": 0,
                    "description": "Normal length"
                },
                "20": {
                    "from": 1,
                    "to": 34,
                    "description": "Attached to stump"
                },
                "60": {
                    "from": 35,
                    "to": 96,
                    "description": "Partially inside stump"
                },
                "98": {
                    "from": 97,
                    "to": 99,
                    "description": "Almost completely inside stump"
                },
                "100": "Completely telescoped"
            },
            "3": {
                "0": {
                    "from": 0,
                    "to": 0,
                    "description": "Normal length"
                },
                "60": {
                    "from": 43,
                    "to": 77,
                    "description": "Attached to stump"
                },
                "85": {
                    "from": 78,
                    "to": 96,
                    "description": "Partially inside stump"
                },
                "98": {
                    "from": 97,
                    "to": 99,
                    "description": "Almost completely inside stump"
                },
                "100": "Completely telescoped"
            },
            "4": {
                "0": {
                    "from": 0,
                    "to": 0,
                    "description": "Normal length"
                },
                "60": {
                    "from": 43,
                    "to": 77,
                    "description": "Attached to stump"
                },
                "85": {
                    "from": 78,
                    "to": 96,
                    "description": "Partially inside stump"
                },
                "98": {
                    "from": 97,
                    "to": 99,
                    "description": "Almost completely inside stump"
                },
                "100": "Completely telescoped"
            }
        },
        "7": {
            "1": {
                
            },
            "2": {
                
            },
            "3": {
                "0": {
                    "from": 0,
                    "to": 0,
                    "description": "Normal length"
                },
                "25": {
                    "from": 1,
                    "to": 50,
                    "description": "Attached to stump"
                },
                "73": {
                    "from": 51,
                    "to": 96,
                    "description": "Partially inside stump"
                },
                "98": {
                    "from": 97,
                    "to": 99,
                    "description": "Almost completely inside stump"
                },
                "100": "Completely telescoped"
            },
            "4": {
                "0": {
                    "from": 0,
                    "to": 0,
                    "description": "Normal length"
                },
                "25": {
                    "from": 1,
                    "to": 50,
                    "description": "Attached to stump"
                },
                "73": {
                    "from": 51,
                    "to": 96,
                    "description": "Partially inside stump"
                },
                "98": {
                    "from": 97,
                    "to": 99,
                    "description": "Almost completely inside stump"
                },
                "100": "Completely telescoped"
            }
        }
    }
};

function concatAnswers(questionList){
    let answers = [];
    for(let q of questionList) answers.push(q.answer);
    return answers.reverse().join(' ');
}

function haveAnswers(questionList){
    for(let q of questionList) if(!q.hasOwnProperty('answer')) return false;
    return true;
}

function selectDescription(value){
    let current = Telescoped_Map[lastPart][lastLevel][lastLimb];
    let fittingDescription;
    let key;

    if(parseInt(value) == 100) return "Completely telescoped";

    for(let q of Object.keys(current).reverse()){
        
        if(current[q].hasOwnProperty("from")){
            if(value >= current[q].from && value <= current[q].to) {
                console.log(current[q].from);
                return current[q].description;
            }
        }
        if(current[q].hasOwnProperty("from") && value > current[q].to){
            key = q;
            fittingDescription = current[q].description;
            break;
        }
    }
    for(let q of Object.keys(current)){
        if(current[q].hasOwnProperty("from") && value < current[q].from){
            key = q;
            fittingDescription += ' and ' + current[q].description;
            break;
        }
    }
    return fittingDescription;
}

function humanize(question){
    if(question.CSVName == "Level_"){
        lastPart = question.part;
        lastLevel = question.answer;
        return levelMap[question.part][question.answer];
    }
    else if(question.CSVName == "Phantom_parts_"){
        lastLimb = question.answer;
        return limbMap[question.part][question.answer];
    }
    else if(question.CSVName == "Telescoped_PC_"){
        return question.answer;
    }
    else if(question.CSVName == "Telescoped_"){
        return selectDescription(question.answer);
    }
    else return question.answer;
}

function collectAnswers(questionList, globalAmpCounter){
    let CSVPart = [];
    let ampCounter = 0;
    for(let q of questionList){
        if(q.type == "root" && q.answer == "yes" && q.ampQuestion){
            CSVPart = [
                ...CSVPart,
                ...collectAnswers(q.elements, (globalAmpCounter + 1)).CSVPart,
                {["Side_"+(globalAmpCounter + 1)]: q.ampQuestion.side},
                {["Extremity_"+(globalAmpCounter + 1)]: q.ampQuestion.extremity}
            ]
            ampCounter += 1;
        }
        else if(q.type == "root" && q.answer == "yes"){
            CSVPart = [
                ...CSVPart,
                ...collectAnswers(q.elements, globalAmpCounter).CSVPart
            ]
        }
        else if(q.type == "root" && q.answer == "no" && !q.ampQuestion){
            CSVPart = [
                ...CSVPart,
                {[q.elements[0].CSVName + (!globalAmpCounter ? '' : globalAmpCounter)]: 0}
            ]
        }
        else if(q.type == "root" && q.answer == "no" && q.ampQuestion){
            return {
                CSVPart: CSVPart,
                ampCounter: ampCounter,
                ignore: 6
            };
        }
        if(q.CSVName == "Telescoped_PC_" && q.hasOwnProperty('answer')){
            CSVPart.push( { ["Telescoped_"+ (!globalAmpCounter ? '' : globalAmpCounter)]: selectDescription(q.answer) } );
        }
        if(q.hasOwnProperty("CSVName") && (q.hasOwnProperty('answer') || q.type == "row" && haveAnswers(q.elements))){
            if(q.type == "row") {
                CSVPart.push( { [q.CSVName + (!globalAmpCounter ? '' : globalAmpCounter)]: concatAnswers(q.elements) } );
            }
            else{
                CSVPart.push( { [q.CSVName + (!globalAmpCounter ? '' : globalAmpCounter)]: humanize(q) } );
            }
        }
    }
    return {
        CSVPart: CSVPart,
        ampCounter: ampCounter,
        ignore: 0
    };
}

export default function convertToCSV(questions, development = 0){
    let ampCounter = 0;
    let remainder = [];
    let ignoreCounter = 0;
    for(let id of Object.keys(questions)){
        let collectedAnswers = collectAnswers(questions[id], ampCounter);
        ignoreCounter += collectedAnswers.ignore;
        if(ignoreCounter){
            ignoreCounter -= 1;
            continue;
        }
        remainder = [
            ...remainder,
            ...collectedAnswers.CSVPart
        ];
        ampCounter += collectedAnswers.ampCounter
    };
    let result = "";
    let columns = "";
    let i = 0;
    let stumpPainN;
    for(let a of remainder){
        i += 1;
        if(i == 5){
            result += ampCounter + ',';
            columns += 'N_amp' + ',';
        }
        if(Object.keys(a)[0] == "Stump_pain_1" || Object.keys(a)[0] == "Stump_pain_2" || Object.keys(a)[0] == "Stump_pain_3" || Object.keys(a)[0] == "Stump_pain_4"){
            stumpPainN = i;
        }
        if(stumpPainN && Object.keys(a)[0] == "Phantom_pain_1" || Object.keys(a)[0] == "Phantom_pain_2" || Object.keys(a)[0] == "Phantom_pain_3" || Object.keys(a)[0] == "Phantom_pain_4"){
            result = result.split(',');
            result.splice(stumpPainN + 1, 0, a[Object.keys(a)[0]]);
            result = result.join(',');
            columns = columns.split(',');
            columns.splice(stumpPainN + 1, 0, Object.keys(a)[0]);
            columns = columns.join(',');
            stumpPainN = undefined;
        }
        else{
            result += a[Object.keys(a)[0]] + ',';
            columns += Object.keys(a)[0] + ',';
        }
    }
    
    return development ? [columns, result].join('\n') : result;
}