export default function verifyAnswers(questions, answers, selectedSliders){
    for(let q of questions){
        let id = q.id;
        if(q.type == "row"){
            if(!verifyAnswers(q.elements, answers, selectedSliders)) return false;
            else continue;
        }
        if(q.type == "root" && answers[q.id] == "yes"){
            if(!verifyAnswers(q.elements, answers, selectedSliders)) return false;
            else continue;
        }
        if(q.type == "date") continue;
        if(q.type == "range" && !selectedSliders[q.id]) return false;
        else if(q.type == "range") continue;
        if(q.type == "root" && !answers[q.id]) return false;
        if(!answers.hasOwnProperty(id) || !answers[id] || answers[id] == "0") return false;
    }
    return true;
}