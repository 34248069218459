import React, { Component } from 'react';
import RootQuestion from './RootQuestion.js';
import ImageListQuestion from './ImageListQuestion';
import ListQuestion from './ListQuestion';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import 'react-calendar/dist/Calendar.css';
import { connect } from 'react-redux';
import imageRules from './questions/imageRules.json';
import {changeSliderStatus} from './slices/surveySlice';
import jquery from 'jquery';

class SuitableField extends React.Component {

    constructor(props) {
        super(props);
    }

    findSuitableElement(question, rowArgument = false) {
        if (question.type == "input") {
            let error = this.props.errors[question.id];
            return (
                    <Form.Group>
                        <Form.Label>{question.question}</Form.Label>
                        <Form.Control
                            type="input"
                            key={question.id}
                            id={question.id}
                            placeholder={question.question}
                            defaultValue={question.answer}
                            onChange={this.props.handleChange}
                            isInvalid={error}
                            
                        />
                        <Form.Control.Feedback type="invalid">
                            {error}
                        </Form.Control.Feedback>
                    </Form.Group>
            );
        }

        if (question.type == "dropdown") {
            if(rowArgument){
                return (
                    <Form.Control
                        as="select"
                        className="my-1 mr-sm-2"
                        key={question.id}
                        id={question.id}
                        defaultValue={this.props.state[question.id] || question.answer}
                        onChange={this.props.handleChange}
                        
                        custom
                    >
                        <option value="0">{(question.placeholder ? question.placeholder : 'Select') + '...'}</option>
                        {question.elements.map((element) => {
                            return <option>{element}</option>
                            // return <option value={question.elements.indexOf(element) + 1}>{element}</option>
                        })}
                    </Form.Control>
                );
            }
            return (
                <Form.Group>
                    <Form.Label style={{ marginBottom: 4 }}>{question.question}</Form.Label>
                    <Form.Control
                        as="select"
                        className="my-1 mr-sm-2"
                        key={question.id}
                        id={question.id}
                        defaultValue={this.props.state[question.id] || question.answer}
                        onChange={this.props.handleChange}
                        
                        custom
                    >
                        <option value="0">{(question.placeholder ? question.placeholder : 'Select') + '...'}</option>
                        {question.elements.map((element) => {
                            return <option>{element}</option>
                            // return <option value={question.elements.indexOf(element) + 1}>{element}</option>
                        })}
                    </Form.Control>
                </Form.Group>
            );
        }

        if (question.type == "date") {
            return (
               ''
            );
        }

        if (question.type == "range") {
            return (
                <Form.Group>
                    <Form.Label>{question.question}<br/>{question.substr}</Form.Label>
                    <Form.Control
                        autoFocus
                        type="range"
                        key={question.id}
                        id={question.id}
                        defaultValue={this.props.state[question.id] || question.answer || 0}
                        onChange={(event) => {
                            this.props.changeSliderStatus({ id: question.id, status: true });
                            event.target.value = !this.props.selectedSliders[question.id] ? 0 : event.target.value;
                            this.props.handleChange(event);
                        }}
                        onClick={(event) => { 
                            if(!this.props.selectedSliders[question.id]){
                                this.props.changeSliderStatus({ id: question.id, status: true }); 
                                event.target.value = 0; 
                                this.props.handleChange(event); 
                            }
                        }}
                        min={(question.discrete && question.discrete.from) || 0}
                        max={(question.discrete && question.discrete.to) || 100}
                        step={(question.discrete && question.discrete.step) || 1}
                        style={{opacity: this.props.selectedSliders[question.id] ? "1" : "0.5"}}
                    />
                    {/* <h2 align="center">{(this.props.nRange || question.answer || 0)}</h2> */}
                    <h2 align="center">{question.discrete && (this.props.nRange || question.answer || 0)}</h2>
                </Form.Group>
            );
        }

        if (question.type == "imageList") {
            // this.props.setImageListId(question.id)
            return (
                <Form.Group>
                <Form.Label>{question.question}</Form.Label>
                <ImageListQuestion
                    elements={question.elements}
                    key={question.id}
                    id={question.id}
                    category={question.category}
                    answer={question.answer}
                    handleImageListChange={this.props.handleListChange}
                />
                </Form.Group>
            );
        }

        if (question.type == "list") {
            // if(rowArgument){
            //     return (
            //         <ListQuestion
            //             elements={question.elements}
            //             key={question.id}
            //             id={question.id}
            //             category={question.category}
            //             answer={question.answer}
            //             handleListChange={this.props.handleListChange}
            //         />
            //     )
            // }
            return (
                <Form.Group>
                <Form.Label>{question.question}</Form.Label>
                <ListQuestion
                    elements={question.elements}
                    key={question.id}
                    id={question.id}
                    category={question.category}
                    answer={question.answer}
                    handleListChange={this.props.handleListChange}
                />
                </Form.Group>
            );
        }

        if (question.type == "root") {
            return (
                <Form.Group>
                    <Form.Label>{question.question}</Form.Label>
                    <RootQuestion
                        id={question.id}
                        setRootValue={this.props.setRootValue}
                        handleChange={this.props.handleChange}
                        elements={question.elements}
                        answer={question.answer}
                        state={this.props.state}
                        nRange={this.props.nRange}
                    />
                </Form.Group>
            );
        }

        if (question.type == "row") {
            let row = [];
            for (let q of question.elements) {
                row.push(<Col>{this.findSuitableElement(q, true)}</Col>);
            }
            // if(window.devicePixelRatio >= 2 || window.innerWidth < 575){
            //     content = (
            //         <Form.Row>
            //             {row}
            //         </Form.Row>
            //     )
            // }
            // else{
            //     if(detect().name == "chrome"){
            //         content = (
            //             <Form.Row style={{marginTop: -20}}>
            //                 {row}
            //             </Form.Row>
            //         )
            //     }
            //     else if(detect().name == "ios"){
            //         content = (
            //             <Form.Row style={{marginTop: 0}}>
            //                 {row}
            //             </Form.Row>
            //         )
            //     }
            //     else {
            //         content = (
            //             <Form.Row style={{marginTop: -20}}>
            //                 {row}
            //             </Form.Row>
            //         )
            //     }
                
            // }
            return (
                <Form.Group>
                    <Form.Label>{question.question}</Form.Label>
                    <Form.Row>
                        {row}
                    </Form.Row>
                </Form.Group>
            );
        }
    }

    render() {
        return (
            <div>
                {this.findSuitableElement(this.props.question)}
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeSliderStatus: (action) => dispatch(changeSliderStatus(action))
    }
};

const mapStateToProps = (state) => {
    return {
      step: state.survey.step,
      selectedImages: state.survey.selectedImages,
      errors: state.survey.errors,
      selectedSliders: state.survey.selectedSliders
    }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(SuitableField);