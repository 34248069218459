function generateIds(questionList, parentId) {
    let i = 1;
    for (let q of questionList) {
        let id = '' + parentId + '.' + i;
        q.id = id;
        if (q.type == "row" || q.type == "root") {
            generateIds(q.elements, id);
        }
        if (q.AppQuery && q.AppQuery.query == "years") {
            let currentYear = new Date().getFullYear();
            q.elements = [...Array(currentYear - q.AppQuery.from).keys()].map(i => i + 1 + q.AppQuery.from);
            if(q.AppQuery.inverse) q.elements.reverse()
        }
        i += 1;
    }
}

export default function prepareQuestions(questions){
    for (let id of Object.keys(questions)) {
        generateIds(questions[id], id)
    }
}