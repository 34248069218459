import ReactDOM from 'react-dom';
import './css/index.css';
import App from './App';
import React, { useState } from 'react';
import { Provider } from 'react-redux';
import { configureStore, selectCount } from '@reduxjs/toolkit';
import humanImageReducer from './slices/humanImageSlice';
import surveyReducer from './slices/surveySlice';

window.React = React;

let store = configureStore({
  reducer: {
    humanImage: humanImageReducer,
    survey: surveyReducer
  }
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);