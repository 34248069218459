import { createSlice } from '@reduxjs/toolkit';
import Questions from '../questions/questions.json';
import LimbPositions from '../questions/limbPositions.json';

export const surveySlice = createSlice({
    name: 'survey',
    initialState: {
        step: 1,
        answeredSteps: [1],
        totalPages: Object.keys(Questions).length + 1,
        errors: {},
        finished: false,
        selectedImages: {
            1: {
                element: 0,
                category: 'humanImage'
            }
        },
        limbPositions: LimbPositions,
        selectedLimbs: {
            
        },
        selectedSliders: {

        }
    },
    reducers: {
        changeStepForward: (state, action) => {
            state.step = action.payload;
            state.answeredSteps = state.answeredSteps.concat([action.payload]);
            if(state.step >= state.totalPages) state.finished = true;
        },
        changeStepBackward: state => {
            state.answeredSteps = state.answeredSteps.slice(0, -1);
            state.step = state.answeredSteps[state.answeredSteps.length - 1];
        },
        updateSelectedImages: (state, action) => {
            state.selectedImages[action.payload.step] = action.payload.image;
        },
        updateSelectedImageCategory: (state, action) => {
            state.selectedImages[action.payload.step].category = action.payload.category;
        },
        updateSelectedLimbs: (state, action) => {
            state.selectedLimbs[action.payload.step] = action.payload.limb || state.selectedLimbs[action.payload.step];
            state.selectedLimbs[action.payload.step].display = false;
            if(!state.selectedLimbs[action.payload.step].position) state.selectedLimbs[action.payload.step].position = {};
            state.selectedLimbs[action.payload.step].position.top = (action.payload.position && action.payload.position.top) || state.selectedLimbs[action.payload.step].position.top || state.limbPositions[action.payload.limb.category].top;
            state.selectedLimbs[action.payload.step].position.left = (action.payload.position && action.payload.position.top) || state.selectedLimbs[action.payload.step].position.left || state.limbPositions[action.payload.limb.category].left;
            state.selectedLimbs[action.payload.step].position.rotate = (action.payload.position && action.payload.position.rotate) || state.selectedLimbs[action.payload.step].position.rotate || 0;
            state.selectedLimbs[action.payload.step].position.scale = (action.payload.position && action.payload.position.scale) || state.selectedLimbs[action.payload.step].position.scale || 1;
        },
        moveSelectedLimb: (state, action) => {
            state.selectedLimbs[action.payload.step].position.top = state.limbPositions[state.selectedLimbs[action.payload.step].category].top + action.payload.position.top || state.selectedLimbs[action.payload.step].position.top;
            state.selectedLimbs[action.payload.step].position.left = state.limbPositions[state.selectedLimbs[action.payload.step].category].left + action.payload.position.left || state.selectedLimbs[action.payload.step].position.left;
            state.selectedLimbs[action.payload.step].position.rotate = action.payload.position.rotate;
            state.selectedLimbs[action.payload.step].position.scale = action.payload.position.scale;
        },
        setLimbVisibility: (state, action) => {
            if(state.selectedLimbs[action.payload.step]) state.selectedLimbs[action.payload.step].display = action.payload.display;
        },
        setUserId: (state, action) => {
            state.userId = action.payload.id;
        },
        setError: (state, action) => {
            state.errors[action.payload.fieldId] = action.payload.message;
        },
        unsetError: (state, action) => {
            state.errors = {};
        },
        changeSliderStatus: (state, action) => {
            state.selectedSliders[action.payload.id] = action.payload.status;
        }
    }
});

export const {
    changeStepForward, changeStepBackward, updateSelectedImages, updateSelectedImageCategory, updateSelectedLimbs, moveSelectedLimb, setLimbVisibility,
    setUserId, setError, unsetError, changeSliderStatus
} = surveySlice.actions;

export default surveySlice.reducer;