import React, { Component } from 'react';
import { updateImage, resetImage } from './slices/humanImageSlice';
import { setLimbVisibility, updateSelectedImages, updateSelectedLimbs } from './slices/surveySlice';
import { connect } from 'react-redux';
import locateInSurveyStore from './locateInSurveyStore';

class ImageListQuestion extends React.Component{
    pathToSvg = './svg/';
    images = [];

    constructor(props){
        super(props);
        let ampLevel = locateInSurveyStore(this.props.selectedImages, this.props.step);
        if((ampLevel.category == "humanImage/leftLowerLevel" || ampLevel.category == "humanImage/rightLowerLevel") && ampLevel.element == 7){
            let i = 0;
            for(let imageName of this.props.elements){
                i += 1;
                if(i < 3) continue;
                this.images.push(require(this.pathToSvg + props.category + '/' + imageName + '.svg').default);
            }
        }
        else if((ampLevel.category == "humanImage/leftLowerLevel" || ampLevel.category == "humanImage/rightLowerLevel") && ampLevel.element == 6){
            let i = 0;
            for(let imageName of this.props.elements){
                i += 1;
                if(i < 2) continue;
                this.images.push(require(this.pathToSvg + props.category + '/' + imageName + '.svg').default);
            }
        }
        else {
            for(let imageName of this.props.elements){
                this.images.push(require(this.pathToSvg + props.category + '/' + imageName + '.svg').default);
            }
        }
        
        this.state = {
            value: props.answer || 0
        }
        this.handleClick = this.handleClick.bind(this);

        this.props.setLimbVisibility({
            step: this.props.step,
            display: false
        });
    }

    handleClick(e){
        this.setState({
            value: e.target.id
        }, () => {
            this.props.handleImageListChange(this.state.value);
            let ampLevel = locateInSurveyStore(this.props.selectedImages, this.props.step);
            let category = (!(ampLevel.category == "humanImage/leftLowerLevel" || ampLevel.category == "humanImage/rightLowerLevel") && ampLevel.element == 7) ? this.props.category+'/half' : this.props.category;
            let limbObject = {
                element: e.target.id,
                category: category
            };
            this.props.updateSelectedLimbs({
                step: this.props.step,
                limb: limbObject
            });
        })
    }

    generateImages(){
        let result = [];
        let i = this.images.length == 2 ? 3 : this.images.length == 3 ? 2 : 1;
        for(let image of this.images){
            result.push(
            <div align="center" tabIndex={i} id={i} 
            onKeyDown={ (event) => { return event.keyCode != 13 || this.handleClick(event); }} 
            onClick={this.handleClick} className={ this.state.value == i ? "selected" : "notSelected"}
            >
                <img id={i} src={image} height="150" width="100"></img>
            </div>)
            i += 1;
        }
        return result;
    }

    generateTiles(images){
        let tiles = [];
        for(let image of images){
            tiles.push(<div>{image}</div>)
        }
        return tiles;
    }

    render(){
        return (
            <div className="tiles">
                {this.generateTiles(this.generateImages())}
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateImage: (action) => dispatch(updateImage(action)),
        resetImage: () => dispatch(resetImage()),
        updateSelectedLimbs: (action) => dispatch(updateSelectedLimbs(action)),
        setLimbVisibility: (action) => dispatch(setLimbVisibility(action))
    }
};

const mapStateToProps = (state) => {
    return {
      step: state.survey.step,
      selectedLimbs: state.survey.selectedLimbs,
      selectedImages: state.survey.selectedImages
    }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(ImageListQuestion);