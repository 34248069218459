import React, { Component } from 'react';
import HumanImage from './HumanImage';
import { connect } from 'react-redux';
import ImageRules from './questions/imageRules.json';

class RightColumn extends React.Component{

    constructor(props){
        super(props)
    }
    
    render(){
        let leftRightLabels = '';
        if(!ImageRules.hideLeftRightLabels.find(e => e == this.props.step)){
            leftRightLabels = (
                <div className="LeftRight"><span className="rightLabel">Right</span><span className="leftLabel">Left</span></div>
            );
        }
        return (
            <div className="right column">
                <div className="rightContainer">
                    <div className="imageAndBlocks">
                        <HumanImage key={this.props.image+this.props.category} svgs={this.props.svgs} labels={leftRightLabels}/>
                    </div>
                    {leftRightLabels}
                </div>
                
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
      image: state.humanImage.value,
      category: state.humanImage.category,
      step: state.survey.step
    }
}
  
export default connect(mapStateToProps, null)(RightColumn)