import React, { Component } from 'react';
import ImageRules from './questions/imageRules.json';
import { updateImage, resetImage } from './slices/humanImageSlice';
import { updateSelectedImages } from './slices/surveySlice';
import { connect } from 'react-redux';
import jquery from 'jquery';
import ReactDOM from 'react-dom';

class ListQuestion extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            value: props.answer || 0,
            showPreview: props.answer ? false : true
        }
        if(!this.state.value){
            this.props.resetImage();
        }
        else if(this.state.value){
            this.props.updateImage({
                element: this.state.value,
                category: ImageRules.questions[this.props.id].category
            });
        }
        this.handleClick = this.handleClick.bind(this);
        this.handleHover = this.handleHover.bind(this);
        this.handleBlur = this.handleHover.bind(this);
    }

    componentDidMount(){
        jquery(".list").mouseleave(
            () => { 
                this.props.updateImage({
                    element: this.state.value,
                    category: this.state.value ? ImageRules.questions[this.props.id].category : "humanImage"
                });
            }
        )
        jquery(document).keydown(
            (event) => {
                if((event.code == "ArrowDown" || event.code == "ArrowUp") && !this.state.focused){
                    jquery(".list").children().first().focus();
                    return;
                }
                if(event.code == "ArrowDown"){
                    jquery(".list div:focus").next().focus();
                }
                else if(event.code == "ArrowUp"){
                    jquery(".list div:focus").prev().focus();
                }
            }
        )
    }

    componentWillUnmount() {
        jquery(document).off('keydown');
    }

    handleClick(e){
        this.setState({
            value: e.target.id,
            showPreview: false
        }, () => {
            this.props.handleListChange(this.state.value)
            if(ImageRules.questions[this.props.id] && e.target.id){
                let imageObject = {
                    element: e.target.id,
                    category: ImageRules.questions[this.props.id].category
                };
                this.props.updateImage(imageObject);
                this.props.updateSelectedImages({
                    step: this.props.step,
                    image: imageObject
                });
            }
        })
    }

    handleHover(e){
        if(ImageRules.questions[this.props.id] && e.target.id){
            this.setState({
                focused: true
            })
            if(e.target.id == this.state.value){
                this.props.updateImage({
                    element: e.target.id,
                    category: ImageRules.questions[this.props.id].category
                });
            }
            else{
                this.props.updateImage({
                    element: e.target.id,
                    category: ImageRules.questions[this.props.id].category+'/preview'
                });
            }
        }
    }

    handleBlur(e){
    }

    generateTiles(elements){
        let tiles = [];
        let i = 1;
        for(let e of elements){
            tiles.push(
                <div 
                    className={ this.state.value == i ? "selected" : "notSelected"} 
                    id={i}
                    key={i}
                    tabIndex={i}
                    onKeyDown={ (event) => { return event.keyCode != 13 || this.handleClick(event); }}
                    onMouseEnter={this.handleHover}
                    onClick={this.handleClick}
                    onFocus={this.handleHover}
                >
                    <span id={i}>{e}</span>
                </div>)
            i += 1;
        }
        return tiles;
    }

    render(){
        return (
            <div className="list">
                {this.generateTiles(this.props.elements)}
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateImage: (action) => dispatch(updateImage(action)),
        resetImage: () => dispatch(resetImage()),
        updateSelectedImages: (action) => dispatch(updateSelectedImages(action))
    }
};

const mapStateToProps = (state) => {
    return {
      step: state.survey.step
    }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(ListQuestion);