import React, { Component } from 'react';
import FieldList from './FieldList';

export default class LeftColumn extends React.Component{
    k = 0;
    constructor(props){
        super(props);

    }
    
    render(){
        let content;
        this.k += 1;
        if(this.props.finished){
            content = (
                <div>
                    <div>
                        <h1>Thank you. <br/>You've successfully completed the survey.
                            
                            {/* <a href={"data:text/plain;charset=utf-8," + encodeURIComponent(this.props.output)} download="DebugData.csv">Click</a> */}
                        </h1>
                    </div>
                    <div className="copyright">© Copyright 2007, 2018, 2021 by Joel Katz</div>
                </div>
            );
        }
        else{
            content = (
                <div>
                    <h1>{this.props.label}</h1>
                    <br/>
                    <FieldList key={this.k} changeStep={this.props.changeStep} backStep={this.props.backStep} questions={this.props.questions} allQuestions={this.props.allQuestions}/>
                </div>
            );
        }
        return (
            <div className="left column">
                <div className="leftContainer" style={{"animation": "fadein 500ms"}}>
                    {content}
                </div>
            </div>
        );
    }
}